import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';

export const iconButtonStyle = StyleSheet.create({
  Button: {
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1.5,
    justifyContent: 'center',
  },
  Font: {
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: 24,
  },
  Notification: {
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 2,
    height: 12,
    position: 'absolute',
    right: -2,
    top: -2,
    width: 12,
  },
});
