import React from 'react';
import { View, Text, ScrollView, SafeAreaView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { currentdeliveryStyle } from './DriverCurrentDelivery.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, TextButton, Timestamp } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import moment from 'moment';
import { DRIVER_PHASES_COMPLETE } from '../../../../common/utils/constants';
import { PhasesEnum } from '../../../../redux/models/phase.model';
import { getAddressString, getPhases, removeCents } from '../../../../common/utils/utils';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';

type Props = {
  fraction: FractionWithDelivery;
  onNext: () => void;
};

export function DriverCurrentDeliveryScreen3({ fraction, onNext }: Props) {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" size="LARGE" type="FRACTION" />

        <Text style={globalStyle.TopBarFont}>{t('currentDeliveryScreen.title')}</Text>
      </View>

      <ScrollView style={globalStyle.MainContainer}>
        <FractionCard
          currentPhase={3}
          description={`${fraction.quantity} ${
            fraction.quantity == 1 ? t('common.fractionUnit') : t('common.fractionUnits')
          }, ${fraction.dimensions.width}cm x ${fraction.dimensions.length}cm x ${
            fraction.dimensions.height
          }cm`}
          fractionId={fraction.uuid.substring(0, 5)}
          pickupAddress={getAddressString(fraction.pickupAddress)}
          reward={removeCents(fraction.deliveryReward)}
          title={t(`fractionTypes.${fraction.type.uuid}`)}
          titleHeader={t('currentDeliveryScreen.fraction')}
          view="DRIVER"
        />

        <HorizontalSpacer space={8} />

        <FractionCard
          disposalPoints={[fraction.disposalPoint]}
          titleHeader={t('common.disposalSite')}
        />

        <View style={currentdeliveryStyle.TimestampsContainer}>
          {getPhases(fraction.transporterPhase.phases, fraction.datePaymentReceived).map(
            (phase) => (
              <Timestamp
                date={moment(phase.dateStarted).format(t('time.dateFormat'))}
                description={DRIVER_PHASES_COMPLETE[phase.name]}
                dotted={phase.dateFinished ? false : true}
                initiated={phase.dateStarted ? true : false}
                isLast={phase.name == PhasesEnum.COMPLETE}
                key={phase.name}
                name={phase.name}
                time={moment(phase.dateStarted).format(t('time.timeFormat'))}
              />
            ),
          )}
        </View>

        <HorizontalSpacer space={140} />
      </ScrollView>

      <View style={currentdeliveryStyle.PickupFractionBtnContainer}>
        <TextButton
          bgColor="grey8"
          icon="TICK"
          onClick={onNext}
          style={currentdeliveryStyle.PickupFractionBtn}
          textColor="white"
          title={t('fractionDetailsScreen.finish')}
          type="SQUARE"
        />
      </View>
    </SafeAreaView>
  );
}
