import React from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import { keyboardAvoiderStyle } from './KeyboardAvoider.style';

type Props = {
  children: React.ReactNode;
};

export function KeyboardAvoider({ children }: Props) {
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={keyboardAvoiderStyle.MainContainer}
    >
      {children}
    </KeyboardAvoidingView>
  );
}
