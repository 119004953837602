export interface Phase {
  id: number;
  name: PhasesEnum;
  order: number;
  dateStarted: Date;
  dateFinished: Date;
}

export enum PhasesEnum {
  O_PUBLISH = 'O_PUBLISH',
  O_PICKUP = 'O_PICKUP',
  O_DELIVERY = 'O_DELIVERY',
  O_REWARD = 'O_REWARD',
  T_PICKUP = 'T_PICKUP',
  T_DELIVERY = 'T_DELIVERY',
  T_REWARD = 'T_REWARD',
  OT_PUBLISH_AND_PICKUP = 'OT_PUBLISH_AND_PICKUP',
  OT_DELIVERY = 'OT_DELIVERY',
  OT_REWARD = 'OT_REWARD',
  COMPLETE = 'COMPLETE',
}
