import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const textButtonStyle = StyleSheet.create({
  Button: {
    alignItems: 'center',
    borderRadius: 4,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 32,
    paddingVertical: 8,
  },
  Font: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
    textTransform: 'uppercase',
  },
  IconSpacing: {
    paddingRight: 16,
  },
});
