import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { RadioButton, TextButton } from '../../../components';
import { addFractionScreenStyle } from './AddFraction.style';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import { FractionCard } from '../../../groups';
import { useGetAllDisposalsQuery } from '../../../../redux/apis/disposalPoints.api';
import { RewardDto } from '../../../../redux/models/reward.model';
import { CurrencySymbolMap } from './../../../../redux/models/reward.model';

export type AddFractionForm3 = {
  isSelfDelivery: boolean;
  disposalPointId: number;
};

type Props = {
  form: AddFractionForm3;
  deliveryReward: RewardDto;
  onChange?: (form?: AddFractionForm3) => void;
  onContinue: (form?: AddFractionForm3) => void;
};

export function AddFractionScreen3(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState(props.form);

  const disposalPointsQuery = useGetAllDisposalsQuery({ pageNumber: 1 });

  useEffect(() => {
    props.onChange ? props.onChange(form) : null;
  }, [form]);

  return (
    <>
      <Text style={addFractionScreenStyle.TitleFont}>
        {t('ownerFractionsScreen.addAFraction.page3.title')}
      </Text>

      <Text style={addFractionScreenStyle.DescriptionFont}>
        {t('ownerFractionsScreen.addAFraction.page3.description')
          .replace(
            '{{currency}}',
            props.deliveryReward.amount ? CurrencySymbolMap[props.deliveryReward.currency] : '',
          )
          .replace(
            '{{amount}}',
            props.deliveryReward.amount
              ? props.deliveryReward.amount.replace('.', t('common.decimalComma'))
              : '',
          )}
      </Text>

      <View style={addFractionScreenStyle.FormContainer}>
        <RadioButton
          label={t('ownerFractionsScreen.addAFraction.page3.selfDelivery')}
          onChange={(value) => setForm({ ...form, isSelfDelivery: value })}
          value={form.isSelfDelivery}
        />

        <HorizontalSpacer space={16} />

        <RadioButton
          label={t('ownerFractionsScreen.addAFraction.page3.driverDelivery')}
          onChange={(value) =>
            setForm({ ...form, isSelfDelivery: !value, disposalPointId: undefined })
          }
          value={!form.isSelfDelivery}
        />
      </View>

      <HorizontalSpacer space={24} />

      {form.isSelfDelivery ? (
        <>
          <Text style={addFractionScreenStyle.DescriptionFont}>
            {t('ownerFractionsScreen.addAFraction.page3.whichDisposalPoint')}
          </Text>
          <HorizontalSpacer space={16} />
        </>
      ) : null}

      {form.isSelfDelivery && disposalPointsQuery.isSuccess ? (
        <FractionCard
          disposalPoints={disposalPointsQuery.data.data}
          onChange={(disposalPointId) => setForm({ ...form, disposalPointId })}
          reward={props.deliveryReward}
          titleHeader={t('common.disposalSite')}
        />
      ) : null}

      <TextButton
        bgColor="white"
        border
        icon="NEXT"
        iconSide="RIGHT"
        onClick={props.onContinue}
        style={addFractionScreenStyle.ContinueButton}
        textColor="black"
        title={t('ownerFractionsScreen.addAFraction.continueBtn')}
        type="ROUND"
      />
    </>
  );
}
