import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { authPersistentReducer } from './slices/auth.slice';
import { authApi } from './apis/auth.api';
import { userApi } from './apis/user.api';
import { fractionReducer } from './slices/fraction.slice';
import { fractionsApi, fractionsApiReducer } from './apis/fraction.api';
import { disposalPointsApi } from './apis/disposalPoints.api';
import { historyReducer } from './slices/history.slice';
import { Platform } from 'react-native';
import { createLogger } from 'redux-logger';

const middlewares = [
  authApi.middleware,
  userApi.middleware,
  fractionsApi.middleware,
  disposalPointsApi.middleware,
];

if (__DEV__ && (Platform.OS === 'ios' || Platform.OS === 'android')) {
  const logger = createLogger({});
  middlewares.push(logger);
}

const allReducers = combineReducers({
  auth: authPersistentReducer,
  fractions: fractionReducer,
  history: historyReducer,
  [fractionsApi.reducerPath]: fractionsApiReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [disposalPointsApi.reducerPath]: disposalPointsApi.reducer,
});

const RESET_NON_PERSISTENT_STATES = 'RESET_NON_PERSISTENT_STATES';
const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_NON_PERSISTENT_STATES) {
    state = { auth: state.auth };
  }
  return allReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const resetAllNonPersistentStates = () => ({ type: RESET_NON_PERSISTENT_STATES });
