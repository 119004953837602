import React from 'react';
import { Text, Image, View } from 'react-native';
import { TextButton } from '../../../components';
import { useTranslation } from 'react-i18next';
import { introScreenStyle } from './IntroScreen.style';
import { loginScreenStyle } from '../LoginScreen.style';

type Props = {
  navigation: any;
};

export function IntroScreen({ navigation }: Props) {
  const { t } = useTranslation();

  return (
    <View style={introScreenStyle.MainContainer}>
      <Image
        source={require('../../../../assets/background/IntroBackground.png')}
        style={introScreenStyle.Background}
      />

      <Text style={loginScreenStyle.TitleFont}>{t('loginScreen.title')}</Text>

      <Text style={loginScreenStyle.SubtitleFont}>{t('loginScreen.subtitle')}</Text>

      <Text style={introScreenStyle.IntroTextFont}>{t('loginScreen.intro')}</Text>

      <View style={introScreenStyle.GetStartedButton}>
        <TextButton
          bgColor="white"
          border
          icon="NEXT"
          iconSide="RIGHT"
          onClick={() => navigation.navigate('LoginScreen')}
          textColor="black"
          title={t('loginScreen.getStarted')}
          type="ROUND"
        />
      </View>
    </View>
  );
}
