import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAllDisposalsQuery } from '../../../../redux/apis/disposalPoints.api';
import { setCurrentDelivery } from '../../../../redux/slices/fraction.slice';
import { useNextPhaseMutation } from '../../../../redux/apis/fraction.api';
import { flatFractionWithDelivery } from '../../../../common/utils/utils';
import { DriverStackProps } from '../../../../navigation/DriverTabNavigator';
import { DisposalPointDto } from '../../../../redux/models/disposalPoint.model';
import { DriverFractionDetailsScreen1 } from './DriverFractionDetailsScreen1';
import { DriverFractionDetailsScreen2 } from './DriverFractionDetailsScreen2';
import { useFocusEffect } from '@react-navigation/native';

export function DriverFractionDetails({ navigation }: DriverStackProps<'DriverFractionDetails'>) {
  const dispatch = useAppDispatch();

  const fraction = useAppSelector((state) => state.fractions.selectedFraction);

  const disposalPointsQuery = useGetAllDisposalsQuery({ pageNumber: 1 });
  const [disposalPoint, setDisposalPoint] = useState<DisposalPointDto>(null);

  useEffect(() => {
    setDisposalPoint(null);
  }, [fraction]);

  const [nextPhase, nextPhaseMutation] = useNextPhaseMutation();

  useEffect(() => {
    if (nextPhaseMutation.isSuccess) {
      const updatedFraction = nextPhaseMutation.data;
      const publishedFraction = flatFractionWithDelivery(updatedFraction, fraction.fractionId);
      dispatch(setCurrentDelivery(publishedFraction));
      navigation.navigate('DriverCurrentDelivery');
    }
  }, [nextPhaseMutation]);

  useFocusEffect(
    useCallback(() => {
      setDisposalPoint(null);
    }, []),
  );

  return (
    <>
      {!disposalPoint && (
        <DriverFractionDetailsScreen1
          disposalPoints={disposalPointsQuery.data?.data}
          fraction={fraction}
          onIconClick={() => navigation.goBack()}
          onNext={setDisposalPoint}
        />
      )}

      {disposalPoint ? (
        <DriverFractionDetailsScreen2
          disposalPoint={disposalPoint}
          fraction={fraction}
          onIconClick={() => setDisposalPoint(null)}
          onNext={() => nextPhase({ id: fraction.deliveryId, disposalPointId: disposalPoint.id })}
        />
      ) : null}
    </>
  );
}
