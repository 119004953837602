export interface Role {
  id: number;
  name: RoleNameEnum;
}

export enum RoleNameEnum {
  ADMIN = 'ADMIN',
  DRIVER = 'DRIVER',
  OWNER = 'CRAFTSMAN',
}
