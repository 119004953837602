import React from 'react';
import { View, Text, ScrollView, SafeAreaView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ownerCurrentdeliveryStyle } from './OwnerCurrentDelivery.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, TextButton } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { useGeoLocation } from '../../../../common/utils/customHooks';
import { getAddressString } from '../../../../common/utils/utils';

type Props = {
  fraction: FractionWithDelivery;
  onNext: () => void;
};

export function OwnerCurrentDeliveryScreen2({ fraction, onNext }: Props) {
  const { t } = useTranslation();
  const { navigateToGoogleMaps } = useGeoLocation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" size="LARGE" type="FRACTION" />

        <Text style={globalStyle.TopBarFont}>{t('currentDeliveryScreen.title')}</Text>
      </View>

      <ScrollView style={globalStyle.MainContainer}>
        <FractionCard
          currentPhase={2}
          disposalPoints={[fraction.disposalPoint]}
          reward={{
            amount: `${(
              parseFloat(fraction.deliveryReward.amount) +
              parseFloat(fraction.materialReward.amount)
            ).toFixed(0)}`,
            currency: fraction.materialReward.currency,
          }}
          titleHeader={t('common.disposalSite')}
          view="DRIVER"
        />

        <HorizontalSpacer space={8} />

        <View style={ownerCurrentdeliveryStyle.MapsBtnContainer}>
          <TextButton
            bgColor="grey8"
            icon="GMAPS"
            onClick={() => navigateToGoogleMaps(getAddressString(fraction.disposalPoint.address))}
            style={ownerCurrentdeliveryStyle.MapsBtn}
            textColor="white"
            title={t('currentDeliveryScreen.openMaps')}
            type="SQUARE"
          />
        </View>

        <HorizontalSpacer space={140} />
      </ScrollView>

      <View style={ownerCurrentdeliveryStyle.PickupFractionBtnContainer}>
        <TextButton
          bgColor="primaryNavy"
          icon="NEXT"
          onClick={onNext}
          style={ownerCurrentdeliveryStyle.StartDrivingBtn}
          textColor="white"
          title={t('currentDeliveryScreen.fractionDelivered')}
          type="ROUND"
        />
      </View>
    </SafeAreaView>
  );
}
