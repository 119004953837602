import * as React from 'react';
import { View, Text } from 'react-native';
import { pickupDateCardStyle } from './PickUpDateCard.style';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { palette } from '../../../common/styles/global.style';
import { CalendarIcon } from '../../components/Icons/CalendarIcon';

type Props = {
  dateTime: Date;
};

export function PickUpDateCard(props: Props) {
  const { t } = useTranslation();

  return (
    <View style={pickupDateCardStyle.MainContainer} testID="PickUpDateCard">
      <View style={pickupDateCardStyle.HeaderContainer}>
        <Text style={pickupDateCardStyle.TitleHeaderFont}>
          {t('ownerFractionsScreen.pickupDate')}
        </Text>
      </View>
      <View style={pickupDateCardStyle.DateTimeContainer}>
        <CalendarIcon color={palette.grey8} height={20} />
        <Text style={pickupDateCardStyle.DateFont}>
          {moment(props.dateTime).format(t('time.dateFormat'))}
        </Text>
        <Text style={pickupDateCardStyle.TimeFont}>
          {'| ' + moment(props.dateTime).format(t('time.timeFormat'))}
        </Text>
      </View>
    </View>
  );
}
