import { useState, useEffect } from 'react';
import * as Location from 'expo-location';
import { Linking } from 'react-native';

export function useGeoLocation() {
  const [location, setLocation] = useState('');
  useEffect(() => {
    (async () => {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status == 'granted') {
        const loc = await Location.getCurrentPositionAsync({});
        setLocation(`${loc.coords.latitude},${loc.coords.longitude}`);
      }
    })();
  }, []);

  const navigateToGoogleMaps = (address: string) => {
    Linking.openURL(
      `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
        location,
      )}&destination=${encodeURIComponent(address)}&travelmode=driving`,
    );
  };

  return { location, navigateToGoogleMaps };
}
