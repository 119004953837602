import { Fonts } from './fonts';
import { StyleSheet } from 'react-native';

export const palette = {
  primaryNavy: '#2853C2',
  primaryNavy2: '#1A2D74',
  accentNavy3: '#90ABF0',
  white: '#fff',
  black: '#000',
  grey1: '#F7F7F7',
  grey3: '#DADADA',
  grey4: '#C5C6C6',
  grey5: '#B1B2B3',
  grey6: '#767676',
  grey7: '#505050',
  grey8: '#303030',
  blue: '#26c6da',
  red2: '#CF6767',
  successGreen1: '#C2E6C2',
  accentMustard: '#FFE177',
  transparent: 'transparent',
  transparentGrey: 'rgba(0,0,0,0.5)',
};

export const globalStyle = StyleSheet.create({
  ErrorFont: {
    color: palette.red2,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginBottom: 0,
    marginTop: 8,
    textAlign: 'right',
    width: '100%',
  },
  FontSmall: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginBottom: 8,
    textAlign: 'left',
    width: '100%',
  },
  HeaderContainer: {
    backgroundColor: palette.white,
    flexDirection: 'row',
    marginBottom: 12,
    marginTop: 12,
    paddingHorizontal: 24,
    position: 'relative',
    width: '100%',
    zIndex: 3,
  },
  MainContainer: {
    backgroundColor: palette.white,
    height: '100%',
    width: '100%',
    zIndex: -1,
  },
  NavBarContainer: {
    borderTopColor: palette.grey3,
    borderTopWidth: 2,
    height: 92,
  },
  StoryBookAlign: {
    alignItems: 'center',
  },
  StorybookDecorator: {
    alignItems: 'center',
    backgroundColor: palette.white,
    height: 800,
    paddingTop: 100,
    width: 400,
  },
  TopBarFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 38,
    letterSpacing: 0.02,
    lineHeight: 32,
    marginLeft: 16,
    marginTop: 3,
    zIndex: 8,
  },
});
