import * as React from 'react';
import { Text, View, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { checkBoxStyle } from './CheckBox.style';
import { palette } from '../../../common/styles/global.style';
import { TickIcon } from '../Icons/TickIcon';

type Props = {
  label?: string;
  initialValue?: boolean;
  disabled?: boolean;
  onChange?: (value?: boolean) => void;
};

export function CheckBox(props: Props) {
  const [value, setValue] = React.useState(props.initialValue || false);

  React.useEffect(() => {
    props.onChange ? props.onChange(value) : null;
  }, [value]);

  const checkUncheck = () => {
    if (!props.disabled) setValue(!value);
  };

  let checkedStyle: any = checkBoxStyle.CheckedBox;
  let uncheckedStyle: any = checkBoxStyle.UncheckedBox;
  let fontStyle: any = checkBoxStyle.Font;
  if (props.disabled) {
    checkedStyle = StyleSheet.compose(checkedStyle, {
      backgroundColor: palette.grey3,
      borderColor: palette.grey3,
    });
    uncheckedStyle = StyleSheet.compose(uncheckedStyle, {
      borderColor: palette.grey3,
    });
    fontStyle = StyleSheet.compose(fontStyle, {
      color: palette.grey3,
    });
  }

  return (
    <TouchableWithoutFeedback
      disabled={props.disabled}
      onPress={checkUncheck}
      testID={`CheckBox_${props.label.replace(' ', '')}`}
    >
      <View style={checkBoxStyle.Box}>
        <View style={value ? checkedStyle : uncheckedStyle}>
          {value ? <TickIcon color={palette.white} height={9} /> : null}
        </View>

        {props.label ? <Text style={fontStyle}>{props.label}</Text> : null}
      </View>
    </TouchableWithoutFeedback>
  );
}
