import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { flatFractionWithDelivery } from '../../../../common/utils/utils';
import {
  useArchiveDeliveryMutation,
  useDeleteFractionAndDeliveryMutation,
  useNextPhaseMutation,
} from '../../../../redux/apis/fraction.api';
import { useTranslation } from 'react-i18next';
import {
  clearFractionsList,
  selectFraction,
  setCurrentDelivery,
} from '../../../../redux/slices/fraction.slice';
import { OwnerCurrentDeliveryScreen1 } from './OwnerCurrentDeliveryScreen1';
import { OwnerCurrentDeliveryScreen2 } from './OwnerCurrentDeliveryScreen2';
import { OwnerCurrentDeliveryScreen3 } from './OwnerCurrentDeliveryScreen3';
import { OwnerStackProps } from '../../../../navigation/OwnerTabNavigator';
import { ConfirmModal } from 'ui/components/Modal/Modal';

export function OwnerCurrentDelivery({ navigation }: OwnerStackProps<'OwnerCurrentDelivery'>) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fraction = useAppSelector(
    (state) => state.fractions.currentDelivery ?? state.fractions.selectedFraction,
  );

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const [nextPhase, nextPhaseMutation] = useNextPhaseMutation();
  const [deleteFraction, deleteMutation] = useDeleteFractionAndDeliveryMutation();
  const [archive, archiveMutation] = useArchiveDeliveryMutation();

  const onDeleteFraction = () => {
    deleteFraction(fraction.deliveryId);
  };

  useEffect(() => {
    if (nextPhaseMutation.isSuccess) {
      const updatedFraction = nextPhaseMutation.data;
      const fractionWithDelivery = flatFractionWithDelivery(updatedFraction, fraction.fractionId); //Considers only one possible current delivery

      if (fractionWithDelivery.isPickedUp && !fractionWithDelivery.isDelivered)
        dispatch(setCurrentDelivery(fractionWithDelivery));
      if (fractionWithDelivery.isDelivered) {
        dispatch(setCurrentDelivery(null));
        dispatch(selectFraction(fractionWithDelivery));
        dispatch(clearFractionsList());
      }
    }
  }, [nextPhaseMutation]);

  useEffect(() => {
    if (archiveMutation.isSuccess || deleteMutation.isSuccess) {
      dispatch(clearFractionsList());
      dispatch(setCurrentDelivery(null));
      navigation.navigate('OwnerFractions');
    }
  }, [archiveMutation, deleteMutation]);

  return (
    <>
      {openConfirmModal ? (
        <ConfirmModal
          action={onDeleteFraction}
          open={openConfirmModal}
          setModal={(val) => setOpenConfirmModal(!val)}
          text={modalMessage}
        />
      ) : null}

      {!fraction.isPickedUp ? (
        <OwnerCurrentDeliveryScreen1
          fraction={fraction}
          navigation={navigation}
          onDelete={() => {
            setModalMessage(t('fractionDetailsScreen.confirmDelete'));
            setOpenConfirmModal(!openConfirmModal);
          }}
          onNext={() => nextPhase({ id: fraction.deliveryId })}
        />
      ) : null}

      {fraction.isPickedUp && !fraction.isDelivered ? (
        <OwnerCurrentDeliveryScreen2
          fraction={fraction}
          onNext={() => nextPhase({ id: fraction.deliveryId })}
        />
      ) : null}

      {fraction.isDelivered ? (
        <OwnerCurrentDeliveryScreen3
          fraction={fraction}
          onNext={() => archive(fraction.deliveryId)}
        />
      ) : null}
    </>
  );
}
