import React from 'react';
import { View, Text, ScrollView, SafeAreaView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { driverFractionDetailsStyle } from './DriverFractionDetails.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, TextButton } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import { getTimeSpan, removeCents } from '../../../../common/utils/utils';
import { DisposalPointDto } from '../../../../redux/models/disposalPoint.model';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';

type Props = {
  fraction: FractionWithDelivery;
  disposalPoint: DisposalPointDto;
  onNext?: () => void;
  onIconClick?: () => void;
};

export function DriverFractionDetailsScreen2({
  fraction,
  disposalPoint,
  onNext,
  onIconClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" onClick={onIconClick} size="LARGE" type="PREVIOUS" />

        <Text style={globalStyle.TopBarFont}>{t('fractionDetailsScreen.overview')}</Text>
      </View>

      <ScrollView style={globalStyle.MainContainer}>
        <FractionCard
          description={`${fraction.quantity} ${
            fraction.quantity == 1 ? t('common.fractionUnit') : t('common.fractionUnits')
          }, ${fraction.dimensions.width}cm x ${fraction.dimensions.length}cm x ${
            fraction.dimensions.height
          }cm`}
          historyHeader={`${t('ownerFractionsScreen.publishedOn').replace(
            '{{time}}',
            getTimeSpan(fraction.dateCreated),
          )}`}
          owner={`${fraction.owner.firstName} ${fraction.owner.lastName
            .substring(0, 1)
            .toUpperCase()}.`}
          pickupAddress={`${fraction.pickupAddress.streetName}, ${fraction.pickupAddress.postCode} ${fraction.pickupAddress.city}`}
          reward={removeCents(fraction.deliveryReward)}
          title={t(`fractionTypes.${fraction.type.uuid}`)}
          view="DRIVER"
        />

        <HorizontalSpacer space={8} />

        <FractionCard disposalPoints={[disposalPoint]} titleHeader={t('common.disposalSite')} />

        <HorizontalSpacer space={100} />
      </ScrollView>

      <View style={driverFractionDetailsStyle.PickupFractionBtnContainer}>
        <TextButton
          bgColor="grey8"
          onClick={onNext}
          style={driverFractionDetailsStyle.PickupFractionBtn}
          textColor="white"
          title={t('fractionDetailsScreen.startDriving')}
          type="SQUARE"
        />
      </View>
    </SafeAreaView>
  );
}
