import React from 'react';
import { BottomTabBarButtonProps } from '@react-navigation/bottom-tabs';
import { t } from 'i18next';
import { NavigationButton } from '../ui/components';

export const fractionsNavBtn = (data: BottomTabBarButtonProps) => (
  <NavigationButton
    color="primaryNavy"
    icon="FRACTION"
    label={t('ownerFractionsScreen.fractions')}
    onClick={(e) => data.onPress(e)}
    selected={data.accessibilityState.selected}
  />
);

export const historyNavBtn = (data: BottomTabBarButtonProps) => (
  <NavigationButton
    color="primaryNavy"
    icon="HISTORY"
    label={t('historyScreen.history')}
    onClick={(e) => data.onPress(e)}
    selected={data.accessibilityState.selected}
  />
);

export const profileNavBtn = (data: BottomTabBarButtonProps) => (
  <NavigationButton
    color="primaryNavy"
    icon="PROFILE"
    label={t('profileScreen.profile')}
    onClick={(e) => data.onPress(e)}
    selected={data.accessibilityState.selected}
  />
);
