import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const statusTagStyle = StyleSheet.create({
  Main: {
    borderColor: palette.grey8,
    borderRadius: 14,
    borderWidth: 2,
    color: palette.grey8,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: 22,
    marginLeft: 0,
    margin: 'auto',
    minWidth: 130,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
});
