import React from 'react';
import { View, Text, ScrollView, SafeAreaView, RefreshControl } from 'react-native';
import { useTranslation } from 'react-i18next';
import { historyDetailsStyle } from './HistoryDetails.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, Timestamp } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import {
  OWNER_AS_DRIVER_PHASES_COMPLETE,
  OWNER_PHASES_COMPLETE,
} from '../../../../common/utils/constants';
import { getPhases, getRewardByRole } from '../../../../common/utils/utils';
import moment from 'moment';
import { PhasesEnum } from '../../../../redux/models/phase.model';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { HistoryDeliveryCard } from 'ui/groups/HistoryDeliveryCard/HistoryDeliveryCard';
import { StatusTagType } from '../../../components/StatusTag/StatusTag';
import { RoleNameEnum } from '../../../../redux/models/role.model';

type Props = {
  fraction: FractionWithDelivery;
  role: RoleNameEnum;
  refreshing?: boolean;
  onRefresh?: () => void;
  onIconClick?: () => void;
};

export function HistoryDetailsScreen({
  fraction,
  role,
  refreshing,
  onRefresh,
  onIconClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" onClick={onIconClick} size="LARGE" type="PREVIOUS" />

        <Text style={globalStyle.TopBarFont}>{t('fractionDetailsScreen.details')}</Text>
      </View>

      <ScrollView
        refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={refreshing} />}
        style={globalStyle.MainContainer}
      >
        <FractionCard
          background
          description={`${fraction.quantity} ${
            fraction.quantity == 1 ? t('common.fractionUnit') : t('common.fractionUnits')
          }, ${fraction.dimensions.width}cm x ${fraction.dimensions.length}cm x ${
            fraction.dimensions.height
          }cm`}
          dispoId={fraction.isInterZeroDelivery ? fraction.interZeroId.toString() : null}
          fractionId={fraction.uuid.substring(0, 5)}
          pickupAddress={`${fraction.pickupAddress.streetName}, ${fraction.pickupAddress.postCode} ${fraction.pickupAddress.city}`}
          reward={getRewardByRole(fraction, role)}
          status={
            fraction.isInterZeroDeliveryError
              ? StatusTagType.REJECTED
              : (fraction.materialReward.statusName as unknown as StatusTagType)
          }
          title={t(`fractionTypes.${fraction.type.uuid}`)}
          titleHeader={moment(fraction.dateCreated).format(t('time.datetimeFormat'))}
          view={fraction.isSelfDelivery ? 'OWNER_AS_DRIVER' : 'OWNER'}
        />

        <HorizontalSpacer space={8} />

        <HistoryDeliveryCard background fraction={fraction} />

        <HorizontalSpacer space={8} />

        <View style={historyDetailsStyle.TimestampsContainer}>
          {getPhases(fraction.ownerPhase.phases, fraction.datePaymentReceived).map((phase) => (
            <Timestamp
              date={moment(phase.dateStarted).format(t('time.dateFormat'))}
              description={
                fraction.isSelfDelivery
                  ? OWNER_AS_DRIVER_PHASES_COMPLETE[phase.name]
                  : OWNER_PHASES_COMPLETE[phase.name]
              }
              dotted={phase.dateFinished ? false : true}
              initiated={phase.dateStarted ? true : false}
              isLast={phase.name == PhasesEnum.COMPLETE}
              key={`${phase.name}`}
              name={`${phase.name}`}
              time={moment(phase.dateStarted).format(t('time.timeFormat'))}
            />
          ))}
        </View>

        <HorizontalSpacer space={200} />
      </ScrollView>
    </SafeAreaView>
  );
}
