import { UserLoginResponseDto } from '../models/user.model';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AuthData } from '../models/auth.model';
import { BaseApiConfig } from './config';
import { Buffer } from 'buffer';

const LOGIN_API = '/v1/users/login';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    login: builder.mutation<AuthData, { username: string; password: string }>({
      query: (body) => ({
        url: LOGIN_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
      transformResponse: (response: UserLoginResponseDto, meta) => {
        const accessToken = meta?.response?.headers.get('Authorization');
        if (!accessToken) return;
        return {
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          mobile: response.mobile,
          accessToken,
          role: response.roleName,
          id: response.id,
          expiresAt: JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii'))
            .exp,
        };
      },
    }),
  }),
});

export const authApiReducer = authApi.reducer;
export const { useLoginMutation } = authApi;
