import * as React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { palette } from 'common/styles/global.style';
import { textButtonStyle } from './TextButton.style';
import { Icon } from '../Icons/Icon';

type Props = {
  title: string;
  type: 'ROUND' | 'SQUARE';
  bgColor: keyof typeof palette;
  textColor: keyof typeof palette;
  disabled?: boolean;
  border?: boolean;
  icon?: 'PLUS' | 'UPLOAD' | 'TICK' | 'NEXT' | 'PREVIOUS' | 'GMAPS' | 'DELETE';
  iconSide?: 'LEFT' | 'RIGHT';
  onClick: () => void;
  style?: any;
};

export function TextButton(props: Props) {
  const dynamicStyle = StyleSheet.create({
    Button: {
      backgroundColor: palette[props.bgColor],
      borderColor: palette[props.textColor],
      borderRadius: props.type == 'SQUARE' ? 4 : 100,
      borderWidth: props.border ? 2 : 0,
    },
  });
  const buttonStyle = StyleSheet.flatten([textButtonStyle.Button, dynamicStyle.Button]);
  const textStyle = StyleSheet.flatten([textButtonStyle.Font, { color: palette[props.textColor] }]);

  const onPress = (): void => {
    if (props.disabled) return;
    props.onClick();
  };

  const icon = <Icon color={props.textColor} icon={props.icon} />;

  const iconSpacing = props.icon ? <View style={textButtonStyle.IconSpacing} /> : null;

  return (
    <View style={props.style}>
      <TouchableOpacity
        disabled={props.disabled}
        onPress={onPress}
        style={buttonStyle}
        testID={`TextButton_${props.icon}_${props.title.replace(' ', '')}`}
      >
        {props.iconSide == 'LEFT' ||
          (!props.iconSide && (
            <>
              {icon}

              {iconSpacing}
            </>
          ))}

        <Text style={textStyle}>{props.title}</Text>

        {props.iconSide == 'RIGHT' && (
          <>
            {iconSpacing}

            {icon}
          </>
        )}
      </TouchableOpacity>
    </View>
  );
}
