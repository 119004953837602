import { StyleSheet } from 'react-native';
import { Fonts } from '../../../../common/styles/fonts';
import { palette } from '../../../../common/styles/global.style';

export const ownerCurrentdeliveryStyle = StyleSheet.create({
  CardContainer: {
    backgroundColor: palette.grey1,
    justifyContent: 'flex-start',
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    width: '100%',
    zindex: -1,
  },
  MapsBtn: {
    width: '80%',
  },
  MapsBtnContainer: {
    alignItems: 'center',
    width: '100%',
    zIndex: 3,
  },
  PickupFractionBtnContainer: {
    alignItems: 'center',
    bottom: 40,
    position: 'absolute',
    width: '100%',
    zIndex: 3,
  },
  StartDrivingBtn: {
    width: '80%',
    marginTop: 10
  },
  TimestampsContainer: {
    backgroundColor: palette.grey1,
  },
  TransporterContainer: {
    backgroundColor: palette.grey1,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
  },
  TransporterContainer2: {
    display: 'flex',
    flexDirection: 'row',
  },
  TransporterFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 24,
    letterSpacing: 0.02,
    lineHeight: 24,
    marginLeft: 10,
    marginRight: 'auto',
    marginTop: 12,
    textAlign: 'left',
  },
  TransporterTitleFont: {
    color: palette.grey6,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 24,
    letterSpacing: 0.04,
    lineHeight: 24,
    marginBottom: 12,
    marginRight: 'auto',
    marginTop: 0,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
});
