import { getStorybookUI, configure, addDecorator } from '@storybook/react-native';
import { withKnobs } from '@storybook/addon-knobs';

addDecorator(withKnobs);
 
// import stories
configure(() => {
  require('../src/ui/components');
  require('../src/ui/groups');
  require('../src/ui/screens');
}, module);

export default getStorybookUI({
  asyncStorage: null, 
  onDeviceUI: false,
});