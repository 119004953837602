import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './config';
import {
  DeliveryRequestResponseDto,
  FractionAddDto,
  FractionResponseDto,
  FractionTypeDTO,
} from './../models/fraction.model';
import { PaginatedRequest, PaginatedResponse } from 'redux/models/paginated.model';
import { RewardDto } from '../models/reward.model';

const DELIVERY_API = '/v1/deliveries';
const DELIVERY_BY_LOGISTIC_TEAM_API = '/v1/deliveries/interzero';
const DELIVERY_NEXT_PHASE_API = '/v1/deliveries/{id}/phases/next';
const DELIVERY_PUBLISH_API = '/v1/deliveries/{id}/publish';
const DELIVERY_ARCHIVE_API = '/v1/deliveries/{id}/archive';
const DELIVERY_REWARD_API = '/v1/deliveries/reward';
const FRACTION_API = '/v1/fractions';
const FRACTION_TYPES_API = '/v1/fractions/types';

export const fractionsApi = createApi({
  reducerPath: 'fractionsApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: [
    'Deliveries',
    'Fractions',
    'Delivery',
    'Fraction',
    'FractionTypes',
    'CurrentDelivery',
    'Archives',
  ],
  endpoints: (builder) => ({
    getAllDeliveries: builder.query<
      PaginatedResponse<DeliveryRequestResponseDto>,
      PaginatedRequest
    >({
      query: (pag: PaginatedRequest) =>
        DELIVERY_API +
        `?pageNumber=${pag.pageNumber ?? 1}&pageSize=${pag.pageSize ?? 10}&sortBy=${
          pag.sortBy ?? 'id'
        }&sortDir=${pag.sortDir ?? 'ASC'}`,
      providesTags: ['Deliveries'],
    }),

    getAllArchivedDeliveries: builder.query<
      PaginatedResponse<DeliveryRequestResponseDto>,
      PaginatedRequest
    >({
      query: (pag: PaginatedRequest) =>
        DELIVERY_API +
        `?pageNumber=${pag.pageNumber ?? 1}&pageSize=${pag.pageSize ?? 10}&sortBy=${
          pag.sortBy ?? 'id'
        }&sortDir=${pag.sortDir ?? 'ASC'}&isArchived=true`,
      providesTags: ['Archives'],
    }),

    getCurrentDeliveries: builder.query<PaginatedResponse<DeliveryRequestResponseDto>, undefined>({
      query: () => DELIVERY_API + `?isCurrent=true`,
      providesTags: ['CurrentDelivery'],
    }),

    createFractionAndDelivery: builder.mutation<DeliveryRequestResponseDto, FractionAddDto>({
      query: (body) => ({
        url: body.isSelfDelivery ? DELIVERY_API : DELIVERY_BY_LOGISTIC_TEAM_API, //TODO: this removes possibility for drivers flow
        method: 'POST',
        body,
      }),
    }),

    deleteFractionAndDelivery: builder.mutation<DeliveryRequestResponseDto, number>({
      query: (id: number) => ({
        url: DELIVERY_API + `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Deliveries'],
    }),

    getFraction: builder.query<FractionResponseDto, number>({
      query: (id: number) => FRACTION_API + `/${id}`,
      providesTags: (fraction) => [{ type: 'Fraction', id: fraction.id }],
    }),

    getDelivery: builder.query<DeliveryRequestResponseDto, number>({
      query: (id: number) => DELIVERY_API + `/${id}`,
      providesTags: (delivery) => [{ type: 'Delivery', id: delivery.id }],
    }),

    getAllFractionTypes: builder.query<FractionTypeDTO[], undefined>({
      query: () => FRACTION_TYPES_API,
      providesTags: ['FractionTypes'],
    }),

    publishDelivery: builder.mutation<DeliveryRequestResponseDto, number>({
      query: (id) => ({
        url: DELIVERY_PUBLISH_API.replace('{id}', id.toString()),
        method: 'PATCH',
      }),
      invalidatesTags: (body) => [{ type: 'Delivery', id: body.id }, 'Deliveries'],
    }),

    archiveDelivery: builder.mutation<DeliveryRequestResponseDto, number>({
      query: (id) => ({
        url: DELIVERY_ARCHIVE_API.replace('{id}', id.toString()),
        method: 'PATCH',
      }),
      invalidatesTags: (body) => [
        { type: 'Delivery', id: body.id },
        'Deliveries',
        'CurrentDelivery',
        'Archives',
      ],
    }),

    getMaterialReward: builder.mutation<RewardDto, FractionAddDto>({
      query: (body) => ({
        url: DELIVERY_REWARD_API + '?type=material',
        method: 'PUT',
        body,
      }),
      transformResponse: (response: RewardDto) => {
        response.amount = parseFloat(response.amount).toFixed(2);
        return response;
      },
    }),

    getDeliveryReward: builder.mutation<RewardDto, FractionAddDto>({
      query: (body) => ({
        url: DELIVERY_REWARD_API + '?type=delivery',
        method: 'PUT',
        body,
      }),
      transformResponse: (response: RewardDto) => {
        response.amount = parseFloat(response.amount).toFixed(2);
        return response;
      },
    }),

    nextPhase: builder.mutation<
      DeliveryRequestResponseDto,
      { id: number; disposalPointId?: number }
    >({
      query: (body) => ({
        url: DELIVERY_NEXT_PHASE_API.replace('{id}', body.id.toString()),
        method: 'PATCH',
        body: { disposalPointId: body.disposalPointId },
      }),
      invalidatesTags: (body) => [
        { type: 'Delivery', id: body.id },
        'CurrentDelivery',
        'Deliveries',
      ],
    }),
  }),
});

export const fractionsApiReducer = fractionsApi.reducer;
export const {
  useCreateFractionAndDeliveryMutation,
  useDeleteFractionAndDeliveryMutation,
  useGetAllDeliveriesQuery,
  useGetAllArchivedDeliveriesQuery,
  useGetCurrentDeliveriesQuery,
  useGetFractionQuery,
  useGetDeliveryQuery,
  useGetAllFractionTypesQuery,
  usePublishDeliveryMutation,
  useArchiveDeliveryMutation,
  useNextPhaseMutation,
  useGetDeliveryRewardMutation,
  useGetMaterialRewardMutation,
} = fractionsApi;
