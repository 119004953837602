import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { ScreensDecorator } from '../../../../../.storybook/screens.decorator';
import { AddFractionScreen1 } from './AddFractionScreen1';
import { AddFractionScreen2 } from './AddFractionScreen2';
import { AddFractionScreen3 } from './AddFractionScreen3';
import { AddFractionScreen4 } from './AddFractionScreen4';

storiesOf('screens/AddFractionScreen', module)
  .addDecorator((story) => <ScreensDecorator>{story()}</ScreensDecorator>)
  .add('screen 1', () => (
    <AddFractionScreen1
      form={{
        type: '',
        typeName: '',
        quantity: '',
        length: '',
        width: '',
        height: '',
      }}
      onContinue={() => void 0}
    />
  ))
  .add('screen 2', () => (
    <AddFractionScreen2
      form={{
        address: '',
        city: '',
        postCode: '',
      }}
      onContinue={() => void 0}
    />
  ))
  .add('screen 3', () => (
    <AddFractionScreen3
      form={{
        isSelfDelivery: true,
        disposalPointId: 1,
      }}
      onContinue={() => void 0}
    />
  ))
  .add('screen 4', () => (
    <AddFractionScreen4
      deliveryReward={50}
      form={{
        type: '1',
        typeName: 'Alu',
        quantity: '',
        length: '',
        width: '',
        height: '',
        address: '',
        city: '',
        postCode: '',
        isSelfDelivery: true,
        disposalPointId: 1,
      }}
      materialReward={200}
      onPublish={() => void 0}
    />
  ));
