import { createSlice } from '@reduxjs/toolkit';
import { FractionWithDelivery } from '../models/fraction.model';
import { RootState } from '../store';
import { fractionActions } from './fraction.slice';

const initialState = {
  selectedFraction: {} as FractionWithDelivery,
  currentDelivery: null as FractionWithDelivery,
  pagedList: {} as {
    [page: number]: FractionWithDelivery[];
  },
  page: 1,
};

//Use same actions of fraction slice, changing the names of each
const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: fractionActions,
});

export const {
  selectFraction: selectHistory,
  addFractionsToPagedList: addHistoriesToPagedList,
  clearFractionsList: clearHistoryList,
  nextFractionsPage: nextHistoryPage,
  setCurrentDelivery: setCurrentHistory,
  clearCurrentDelivery: clearCurrentHistory,
} = historySlice.actions;

export const getHistoryList = (state: RootState) => {
  const list = [] as FractionWithDelivery[];
  for (let i = 1; i <= state.history.page; i++)
    state.history.pagedList[i] && list.push(...state.history.pagedList[i]);
  return list;
};

export const historyReducer = historySlice.reducer;
