import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: any;
};

export function TelephoneIcon(props: Props) {
  return (
    <Svg
      fill="none"
      height={props.height}
      width={(13 / 16) * props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M4.76177 6.83313L6.06606 5.63813C6.4227 5.31074 6.67336 4.88425 6.78588 4.41339C6.8984 3.94253 6.86765 3.44879 6.69756 2.99553L6.14136 1.51029C5.93355 0.955803 5.52217 0.501401 4.99101 0.239659C4.45986 -0.0220827 3.84889 -0.0714727 3.28259 0.101552C1.19863 0.739126 -0.403197 2.67614 0.0898605 4.97627C0.414113 6.48944 1.03469 8.38881 2.21025 10.4096C3.38825 12.4353 4.73383 13.9315 5.88754 14.9832C7.62903 16.568 10.1186 16.1721 11.7204 14.6783C12.1498 14.278 12.4104 13.729 12.4489 13.1432C12.4875 12.5574 12.3013 11.9789 11.9281 11.5257L10.908 10.287C10.6003 9.91237 10.1876 9.63841 9.72293 9.50033C9.25826 9.36225 8.76291 9.36637 8.30061 9.51215L6.61498 10.0429C6.17967 9.59361 5.7982 9.09517 5.47828 8.55761C5.16949 8.01398 4.92884 7.43438 4.76177 6.83191V6.83313Z"
        fill={props.color}
      />
    </Svg>
  );
}
