import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FractionWithDelivery } from '../models/fraction.model';
import { RootState } from '../store';

const initialState = {
  selectedFraction: {} as FractionWithDelivery,
  currentDelivery: null as FractionWithDelivery,
  pagedList: {} as {
    [page: number]: FractionWithDelivery[];
  },
  page: 1,
};

export const fractionActions = {
  selectFraction(state, action: PayloadAction<FractionWithDelivery>) {
    return {
      ...state,
      selectedFraction: action.payload,
    };
  },

  addFractionsToPagedList(
    state,
    action: PayloadAction<{ page: number; fractions: FractionWithDelivery[] }>,
  ) {
    return {
      ...state,
      pagedList: {
        ...state.pagedList,
        [action.payload.page]: action.payload.fractions,
      },
    };
  },

  clearFractionsList(state) {
    return {
      ...state,
      pagedList: {},
      page: 1,
    };
  },

  nextFractionsPage(state) {
    return {
      ...state,
      page: state.page + 1,
    };
  },

  setCurrentDelivery(state, action: PayloadAction<FractionWithDelivery>) {
    return {
      ...state,
      currentDelivery: action.payload,
    };
  },

  clearCurrentDelivery(state) {
    return {
      ...state,
      currentDelivery: null,
    };
  },
};

const fractionSlice = createSlice({
  name: 'fraction',
  initialState,
  reducers: fractionActions,
});

export const {
  selectFraction,
  addFractionsToPagedList,
  clearFractionsList,
  nextFractionsPage,
  setCurrentDelivery,
  clearCurrentDelivery,
} = fractionSlice.actions;

export const getFractionsList = (state: RootState) => {
  const list = [] as FractionWithDelivery[];
  for (let i = 1; i <= state.fractions.page; i++)
    state.fractions.pagedList[i] && list.push(...state.fractions.pagedList[i]);
  return list;
};

export const fractionReducer = fractionSlice.reducer;
