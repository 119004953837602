import * as React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { rewardCardStyle } from './RewardCard.style';
import { Icon } from '../../components/Icons/Icon';
import { useTranslation } from 'react-i18next';
import { CurrencySymbolMap, RewardDto } from './../../../redux/models/reward.model';

type Props = {
  reward: RewardDto;
  title: string;
  description: string;
  address: string;
};

export function RewardCard(props: Props) {
  const { t } = useTranslation();

  const getExtraCharWidth = () => {
    const valueStringLenght = props.reward.amount.length;
    return valueStringLenght > 5 ? (valueStringLenght - 5) * 30 : 0;
  };

  return (
    <View style={rewardCardStyle.MainContainer} testID="RewardCard">
      <Image
        source={require('../../../assets/background/RewardCardBg.png')}
        style={rewardCardStyle.MainBg}
      />

      <View
        style={StyleSheet.flatten([
          rewardCardStyle.BlueBg,
          {
            marginLeft: 97 - getExtraCharWidth() / 2,
            width: 180 + getExtraCharWidth(),
          },
        ])}
      />

      <View
        style={StyleSheet.flatten([
          rewardCardStyle.WhiteBg,
          {
            marginLeft: 89 - getExtraCharWidth() / 2,
            width: 180 + getExtraCharWidth(),
          },
        ])}
      />

      <View style={rewardCardStyle.TextContainer}>
        <Text style={rewardCardStyle.RecyclingFont}>{t('ui.groups.rewardCard.rewardValue')}</Text>

        <View style={rewardCardStyle.RewardContainer}>
          <Text style={rewardCardStyle.CurrencyFont}>
            {props.reward.amount ? CurrencySymbolMap[props.reward.currency] : ''}
          </Text>

          <Text style={rewardCardStyle.RewardFont}>
            {props.reward.amount ? props.reward.amount.replace('.', t('common.decimalComma')) : ''}
          </Text>
        </View>

        <Text style={rewardCardStyle.TitleFont}>{props.title}</Text>

        <View style={rewardCardStyle.DimentionIcon}>
          <Icon color="grey7" icon="DIMENSION" />
        </View>

        <Text style={rewardCardStyle.DescriptionFont}>{props.description}</Text>

        <View style={rewardCardStyle.LocationIcon}>
          <Icon color="grey7" icon="LOCATION" />
        </View>

        <Text style={rewardCardStyle.DescriptionFont}>{props.address}</Text>
      </View>
    </View>
  );
}
