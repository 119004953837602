import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

type Props = {
  xmlns?: string;
};

export function GmapsIcon(props: Props) {
  return (
    <Svg fill="none" height={24} width={17} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#a)">
        <Path
          d="M4.607 17.779a24.237 24.237 0 0 1 1.887 2.788c.481.915.682 1.535 1.034 2.636.215.609.41.79.83.79.456 0 .663-.308.824-.786.332-1.04.593-1.835 1.005-2.585.807-1.449 1.81-2.736 2.797-3.973.267-.35 1.993-2.39 2.77-4 0 0 .955-1.767.955-4.236 0-2.31-.942-3.912-.942-3.912l-2.712.728-1.646 4.345-.408.6-.081.108-.109.136-.19.217-.272.272-1.466 1.197-3.666 2.121-.61 3.554Z"
          fill="#34A853"
        />

        <Path
          d="M.823 12.353c.895 2.047 2.62 3.848 3.788 5.427l6.2-7.359s-.873 1.145-2.458 1.145a3.18 3.18 0 0 1-3.19-3.193c0-1.221.733-2.06.733-2.06l-4.21 1.13-.863 4.91Z"
          fill="#FBBC04"
        />

        <Path
          d="M10.88.378c2.06.666 3.823 2.062 4.889 4.122l-4.956 5.917s.734-.854.734-2.068c0-1.822-1.532-3.183-3.186-3.183-1.565 0-2.463 1.142-2.463 1.142V2.581L10.88.378Z"
          fill="#4285F4"
        />

        <Path
          d="M1.959 2.993C3.189 1.518 5.353 0 8.337 0c1.448 0 2.538.38 2.538.38L5.893 6.313h-3.53l-.404-3.32Z"
          fill="#1A73E8"
        />

        <Path
          d="M.823 12.353S0 10.738 0 8.398c0-2.212.858-4.145 1.959-5.405l3.937 3.32-5.073 6.04Z"
          fill="#EA4335"
        />
      </G>

      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h16.709v24H0z" fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
