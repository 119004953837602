import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { flatFractionWithDelivery } from '../../../../common/utils/utils';
import {
  useArchiveDeliveryMutation,
  useNextPhaseMutation,
} from '../../../../redux/apis/fraction.api';
import { setCurrentDelivery } from '../../../../redux/slices/fraction.slice';
import { DriverStackProps } from '../../../../navigation/DriverTabNavigator';
import { DriverCurrentDeliveryScreen1 } from './DriverCurrentDeliveryScreen1';
import { DriverCurrentDeliveryScreen2 } from './DriverCurrentDeliveryScreen2';
import { DriverCurrentDeliveryScreen3 } from './DriverCurrentDeliveryScreen3';

export function DriverCurrentDelivery({ navigation }: DriverStackProps<'DriverCurrentDelivery'>) {
  const dispatch = useAppDispatch();

  const fraction = useAppSelector((state) => state.fractions.currentDelivery);

  const [nextPhase, nextPhaseMutation] = useNextPhaseMutation();

  const [archive, archiveMutation] = useArchiveDeliveryMutation();

  useEffect(() => {
    if (nextPhaseMutation.isSuccess) {
      const updatedFraction = nextPhaseMutation.data;
      const fractionWithDelivery = flatFractionWithDelivery(updatedFraction, fraction.fractionId); //Considers only one possible current delivery
      dispatch(setCurrentDelivery(fractionWithDelivery));
    }
  }, [nextPhaseMutation]);

  useEffect(() => {
    if (archiveMutation.isSuccess) navigation.navigate('DriverFractions');
  }, [archiveMutation]);

  if (!fraction) return null;

  return (
    <>
      {!fraction.isPickedUp ? (
        <DriverCurrentDeliveryScreen1
          fraction={fraction}
          onNext={() => nextPhase({ id: fraction.deliveryId })}
        />
      ) : null}

      {fraction.isPickedUp && !fraction.isDelivered ? (
        <DriverCurrentDeliveryScreen2
          fraction={fraction}
          onNext={() => nextPhase({ id: fraction.deliveryId })}
        />
      ) : null}

      {fraction.isDelivered ? (
        <DriverCurrentDeliveryScreen3
          fraction={fraction}
          onNext={() => archive(fraction.deliveryId)}
        />
      ) : null}
    </>
  );
}
