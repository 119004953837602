export enum RewardStatusEnum {
  REJECTED = 'REJECTED',
  OPEN = 'OPEN',
  SUCCESSFUL = 'SUCCESSFUL',
}

export enum RewardCurrencyEnum {
  EUR = 'EUR',
}

export const CurrencySymbolMap = {
  [RewardCurrencyEnum.EUR]: '€',
};

export interface RewardDto {
  amount: string;
  currency: RewardCurrencyEnum;
  statusUUID?: number;
  statusName?: RewardStatusEnum;
}
