import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './config';

const LIST_USERS_API = '/v1/userinfo';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserInfo: builder.query<null, null>({
      query: () => LIST_USERS_API,
    }),
  }),
});

export const userApiReducer = userApi.reducer;

export const { useGetUserInfoQuery } = userApi;
