import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const dropdownStyle = StyleSheet.create({
  Dropdown: {
    backgroundColor: palette.grey1,
    borderColor: palette.grey3,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 50,
    letterSpacing: 0.02,
    lineHeight: 24,
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
  },
});
