import { StyleSheet } from 'react-native';
import { palette } from 'common/styles/global.style';

export const profilePictureStyle = StyleSheet.create({
  Notification: {
    backgroundColor: palette.primaryNavy,
    borderColor: palette.white,
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 2,
    height: 12,
    position: 'absolute',
    right: -2,
    top: -2,
    width: 12,
  },
  Picture: {
    borderRadius: 20,
    height: 40,
    width: 40,
  },
});
