import { t } from 'i18next';
import { PhasesEnum } from '../../redux/models/phase.model';

export const OWNER_PHASES = {
  [PhasesEnum.O_PUBLISH]: t('ui.groups.fractionCard.owner.phase1'),
  [PhasesEnum.O_PICKUP]: t('ui.groups.fractionCard.owner.phase2'),
  [PhasesEnum.O_DELIVERY]: t('ui.groups.fractionCard.owner.phase3'),
  [PhasesEnum.O_REWARD]: t('ui.groups.fractionCard.owner.phase4'),
};

export const OWNER_PHASES_COMPLETE = {
  ...OWNER_PHASES,
  [PhasesEnum.COMPLETE]: t('ui.groups.fractionCard.phase5'),
};

export const OWNER_AS_DRIVER_PHASES = {
  [PhasesEnum.OT_PUBLISH_AND_PICKUP]: t('ui.groups.fractionCard.ownerAsDriver.phase1'),
  [PhasesEnum.OT_DELIVERY]: t('ui.groups.fractionCard.ownerAsDriver.phase2'),
  [PhasesEnum.OT_REWARD]: t('ui.groups.fractionCard.ownerAsDriver.phase3'),
};

export const OWNER_AS_DRIVER_PHASES_COMPLETE = {
  ...OWNER_AS_DRIVER_PHASES,
  [PhasesEnum.COMPLETE]: t('ui.groups.fractionCard.phase5'),
};

export const DRIVER_PHASES = {
  [PhasesEnum.T_PICKUP]: t('ui.groups.fractionCard.driver.phase1'),
  [PhasesEnum.T_DELIVERY]: t('ui.groups.fractionCard.driver.phase2'),
  [PhasesEnum.T_REWARD]: t('ui.groups.fractionCard.driver.phase3'),
};

export const DRIVER_PHASES_COMPLETE = {
  ...DRIVER_PHASES,
  [PhasesEnum.COMPLETE]: t('ui.groups.fractionCard.phase5'),
};
