import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: any;
};

export function LocationIcon(props: Props) {
  return (
    <Svg
      fill="none"
      height={props.height}
      width={(14 / 16) * props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M6.505 0C3.09 0 0 2.618 0 6.668c0 2.585 1.992 5.626 5.968 9.13a.827.827 0 0 0 1.082 0c3.968-3.504 5.96-6.545 5.96-9.13C13.01 2.618 9.92 0 6.505 0Zm0 8.131a1.631 1.631 0 0 1-1.626-1.626c0-.895.731-1.626 1.626-1.626.894 0 1.626.731 1.626 1.626 0 .894-.732 1.626-1.626 1.626Z"
        fill={props.color}
      />
    </Svg>
  );
}
