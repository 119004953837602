import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RootState } from '../store';
import Constants from 'expo-constants';

const BACKEND_BASE_URL = Constants.manifest.extra.backendUrl;

export const BaseApiConfig: FetchBaseQueryArgs = {
  baseUrl: BACKEND_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;
    if (token && token !== '') {
      headers.set('Authorization', token);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
};
