import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { ScreensDecorator } from '../../../../../.storybook/screens.decorator';
import { OwnerFractionsScreen } from './OwnerFractionsScreen';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';

const fractions: FractionWithDelivery[] = [
  {
    id: 1,
    type: {
      name: 'Fraction type 1',
    },
    uuid: 'hgfhgfgh',
    owner: {
      firstName: 'John',
      lastName: 'Doe',
    },
    pickupAddress: {
      streetName: 'Street 2',
      city: 'Las Vegas',
      postCode: '99999',
    },
    dateCreated: new Date(),
    quantity: 7,
    dimensions: {
      width: 1,
      height: 2,
      length: 3,
    },
    isSelfDelivery: false,
    deliveryReward: {
      amount: '50',
      currency: 'EUR',
    },
  },
  {
    id: 2,
    type: {
      name: 'Fraction type 2',
    },
    uuid: 'cdsdfvsdf',
    owner: {
      firstName: 'Mary',
      lastName: 'Doe',
    },
    pickupAddress: {
      streetName: 'Street 6',
      city: 'Las Vegas',
      postCode: '99999',
    },
    dateCreated: new Date(),
    quantity: 2,
    dimensions: {
      width: 15,
      height: 3,
      length: 23,
    },
    isSelfDelivery: true,
    deliveryReward: {
      amount: '345',
      currency: 'EUR',
    },
  },
  {
    id: 3,
    type: {
      name: 'Fraction type 1',
    },
    uuid: 'asfdasdfa',
    owner: {
      firstName: 'John',
      lastName: 'Doe',
    },
    pickupAddress: {
      streetName: 'Street 2',
      city: 'Las Vegas',
      postCode: '99999',
    },
    dateCreated: new Date(),
    quantity: 7,
    dimensions: {
      width: 1,
      height: 2,
      length: 3,
    },
    isSelfDelivery: false,
    deliveryReward: {
      amount: '50',
      currency: 'EUR',
    },
  },
  {
    id: 4,
    type: {
      name: 'Fraction type 2',
    },
    uuid: 'cdsdfvsdf',
    owner: {
      firstName: 'Mary',
      lastName: 'Doe',
    },
    pickupAddress: {
      streetName: 'Street 6',
      city: 'Las Vegas',
      postCode: '99999',
    },
    dateCreated: new Date(),
    quantity: 2,
    dimensions: {
      width: 15,
      height: 3,
      length: 23,
    },
    isSelfDelivery: false,
    deliveryReward: {
      amount: '345',
      currency: 'EUR',
    },
  },
] as FractionWithDelivery[];

storiesOf('screens/OwnerFractions', module)
  .addDecorator((story) => <ScreensDecorator>{story()}</ScreensDecorator>)
  .add('screen', () => <OwnerFractionsScreen fractions={fractions} />);
