import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_US from './en_US.json';
import de_DE from './de_DE.json';

const LANGUAGES = {
  en_US: {
    translation: en_US,
  },
  de_DE: {
    translation: de_DE,
  },
};

i18n.use(initReactI18next).init({
  resources: LANGUAGES,
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
  lng: 'de_DE',
  fallbackLng: 'de_DE',
  compatibilityJSON: 'v3',
});
