import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { PickUpDateCard } from './PickUpDateCard';
import { ScreensDecorator } from '../../../../.storybook/screens.decorator';

storiesOf('groups/PickUpDateCard', module)
  .addDecorator((story) => <ScreensDecorator>{story()}</ScreensDecorator>)
  .add('owner view Phase 1', () => (
    <PickUpDateCard
      {...object('props', {
        dateTime: new Date(),
      })}
    />
  ));
