import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const profileScreenStyle = StyleSheet.create({
  Background: {
    height: 220,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 390,
    zIndex: -1,
  },
  LabelFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: 24,
    marginTop: 16,
    textTransform: 'uppercase',
  },
  MainContainer: {
    backgroundColor: palette.grey1,
    justifyContent: 'flex-start',
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 0,
    width: '100%',
    zindex: -1,
  },
  SignOutButton: {
    width: '100%',
  },
  SignOutButtonContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 40,
    position: 'absolute',
    width: '80%',
    zIndex: 3,
  },
  ValueFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginTop: 8,
  },
});
