import * as React from 'react';
import { View } from 'react-native';
import { palette } from '../../../common/styles/global.style';
import { pageIndexStyle } from './PageIndex.style';
import { HorizontalLine } from './../../components/Icons/HorizontalLine';
import { IconButton } from '../../components';
import { useEffect } from 'react';

type Props = {
  number: number;
  value: number;
  style?: any;
  onChange?: (value: number) => void;
};

export function PageIndex(props: Props) {
  const [value, setValue] = React.useState(props.value);

  useEffect(() => setValue(props.value), [props.value]);
  useEffect(() => (props.onChange ? props.onChange(value) : null), [value]);

  let pages = null;
  if (props.number > 1) {
    const indexes = [];
    for (let i = 0; i <= props.number - 2; i++) indexes.push(i);
    pages = indexes.map((i) => (
      <View key={i} style={pageIndexStyle.Container}>
        <HorizontalLine
          color={palette.primaryNavy}
          dotted={value <= i + 1}
          style={pageIndexStyle.Line}
          width={40}
        />

        <IconButton
          color={value >= i + 2 ? 'primaryNavy' : 'grey3'}
          disabled={value <= i + 2}
          onClick={() => setValue(i + 2)}
          reverseColor={value == i + 2}
          size="SMALL"
          text={`${i + 2}`}
          type="TEXT"
        />
      </View>
    ));
  }

  return (
    <View style={props.style}>
      <View style={pageIndexStyle.Container}>
        <IconButton
          color="primaryNavy"
          onClick={() => setValue(1)}
          reverseColor={value == 1}
          size="SMALL"
          text="1"
          type="TEXT"
        />

        {pages}
      </View>
    </View>
  );
}
