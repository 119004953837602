/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { NavigationButton } from './NavigationButton';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';

const props = {
  selected: false,
  label: 'Fraction',
  color: 'primaryNavy' as const,
  icon: 'FRACTION' as const,
};

storiesOf('components/NavigationButton', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>
        <View style={{ flex: 1, flexDirection: 'row', width: '100%' }}>{story()}</View>
      </View>
    </View>
  ))
  .add('unselected fraction', () => <NavigationButton {...object('props', props)} />)
  .add('selected profile', () => (
    <NavigationButton
      {...object('props', { ...props, selected: true, icon: 'PROFILE', label: 'Profile' })}
    />
  ))
  .add('disabled history', () => (
    <NavigationButton
      {...object('props', { ...props, icon: 'HISTORY', label: 'History', disabled: true })}
    />
  ));
