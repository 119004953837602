import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { ScreensDecorator } from '../../../../../.storybook/screens.decorator';
import { DriverFractionDetailsScreen1 } from './DriverFractionDetailsScreen1';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { DriverFractionDetailsScreen2 } from './DriverFractionDetailsScreen2';
import { DisposalPointDto } from '../../../../redux/models/disposalPoint.model';
import { PhasesEnum } from '../../../../redux/models/phase.model';

const fraction: FractionWithDelivery = {
  type: {
    name: 'Fraction type',
  },
  id: 1,
  uuid: 'asfdasdfa',
  owner: {
    firstName: 'John',
    lastName: 'Doe',
  },
  pickupAddress: {
    streetName: 'Street 2',
    city: 'Las Vegas',
    postCode: '99999',
  },
  dateCreated: new Date(),
  datePublished: new Date(),
  materialReward: {
    amount: '200',
    currency: 'EUR',
  },
  quantity: 7,
  dimensions: {
    width: 1,
    height: 2,
    length: 3,
  },
  transporterPhase: {
    currentPhaseId: 1,
    currentPhaseName: PhasesEnum.T_PICKUP,
    phases: [
      {
        id: 1,
        name: PhasesEnum.T_PICKUP,
        dateStarted: new Date(),
        order: 1,
      },
    ],
  },
  deliveryReward: {
    amount: '50',
    currency: 'EUR',
  },
  disposalPoint: {
    address: {
      streetName: 'Street 7',
      city: 'Berlin',
      postCode: '12345',
    },
  },
  isSelfDelivery: false,
  fractionId: 1,
  deliveryId: 1,
} as FractionWithDelivery;

const disposalPoints: DisposalPointDto[] = [
  {
    id: 1,
    uuid: '342342',
    name: 'disposal A',
    address: {
      city: 'Hamburg',
      postCode: '12312',
      streetName: 'strasse 2',
      name: '',
      country: '',
    },
  },
  {
    id: 2,
    uuid: '342342',
    name: 'disposal B',
    address: {
      city: 'Hannover',
      postCode: '43234',
      streetName: 'strasse 8',
      name: '',
      country: '',
    },
  },
];

storiesOf('screens/DriverFractionDetails', module)
  .addDecorator((story) => <ScreensDecorator>{story()}</ScreensDecorator>)
  .add('screen 1', () => (
    <DriverFractionDetailsScreen1 disposalPoints={disposalPoints} fraction={fraction} />
  ))
  .add('screen 2', () => (
    <DriverFractionDetailsScreen2 disposalPoint={disposalPoints[0]} fraction={fraction} />
  ));
