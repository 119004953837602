import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  rotation?: number;
  xmlns?: string;
  style?: any;
};

export function CalendarIcon(props: Props) {
  return (
    <Svg
      fill="none"
      height={props.height}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M16 13H13C12.45 13 12 13.45 12 14V17C12 17.55 12.45 18 13 18H16C16.55 18 17 17.55 17 17V14C17 13.45 16.55 13 16 13ZM16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3ZM18 20H6C5.45 20 5 19.55 5 19V9H19V19C19 19.55 18.55 20 18 20Z"
        fill={props.color}
        scale={props.height / 22}
      />
    </Svg>
  );
}
