import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { CheckBox } from './CheckBox';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';

const props = {
  label: 'Checkbox',
};

storiesOf('components/CheckBox', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('unchecked', () => <CheckBox {...object('props', props)} />)
  .add('checked', () => <CheckBox {...object('props', { ...props, initialValue: true })} />)
  .add('disabled', () => (
    <CheckBox {...object('props', { ...props, initialValue: true, disabled: true })} />
  ));
