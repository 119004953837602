import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const navigationButtonStyle = StyleSheet.create({
  Button: {
    alignItems: 'center',
    borderTopWidth: 4,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 92,
    justifyContent: 'flex-start',
    paddingTop: 12,
  },
  Font: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
    marginTop: 10,
  },
});
