import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { flatFractionWithDelivery } from '../../../../common/utils/utils';
import { useGetDeliveryQuery } from '../../../../redux/apis/fraction.api';
import { useFocusEffect } from '@react-navigation/native';
import { OwnerStackProps } from '../../../../navigation/OwnerTabNavigator';
import { HistoryDetailsScreen } from './HistoryDetailsScreen';
import { selectHistory } from 'redux/slices/history.slice';

export function HistoryDetails({ navigation }: OwnerStackProps<'OwnerFractionDetails'>) {
  const dispatch = useAppDispatch();

  const fraction = useAppSelector((state) => state.history.selectedFraction);
  const [refreshing, setRefreshing] = useState(false);

  const deliveryQuery = useGetDeliveryQuery(fraction.deliveryId);

  useEffect(() => {
    if (deliveryQuery.isSuccess) {
      setRefreshing(false);
      const fractionWithDelivery = flatFractionWithDelivery(
        deliveryQuery.data,
        fraction.fractionId,
      );
      dispatch(selectHistory(fractionWithDelivery));
    }
  }, [deliveryQuery.data, deliveryQuery.isFetching]);

  const user = useAppSelector((state) => state.auth);

  useFocusEffect(useCallback(() => deliveryQuery.refetch(), []));

  const onRefresh = () => {
    setRefreshing(true);
    deliveryQuery.refetch();
  };

  return (
    <HistoryDetailsScreen
      fraction={fraction}
      onIconClick={() => navigation.navigate('History')}
      onRefresh={onRefresh}
      refreshing={refreshing}
      role={user.role}
    />
  );
}
