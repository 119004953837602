import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { PhaseDisplay } from './PhaseDisplay';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';

const props = {
  currentPhase: 2,
  phaseDescriptions: ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4', 'Phase 5'],
};

storiesOf('components/PhaseDisplay', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('2/5', () => <PhaseDisplay {...object('props', props)} />)
  .add('6/7', () => (
    <PhaseDisplay
      {...object('props', {
        currentPhase: 6,
        phaseDescriptions: [
          'Phase 1',
          'Phase 2',
          'Phase 3',
          'Phase 4',
          'Phase 5',
          'Phase 6 with a very very very very very very very very very very very very very very very very very very very very long description',
          'Phase 7',
        ],
      })}
    />
  ));
