import React from 'react';
import { Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TextButton } from '../../../components';
import { addFractionScreenStyle } from './AddFraction.style';
import { RewardCard } from '../../../groups';
import { AddFractionForm } from './AddFraction';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import { CurrencySymbolMap, RewardDto } from './../../../../redux/models/reward.model';

type Props = {
  form: AddFractionForm;
  materialReward: RewardDto;
  deliveryReward?: RewardDto;
  onPublish: (form?: AddFractionForm) => void;
};

export function AddFractionScreen4(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Text style={addFractionScreenStyle.TitleFont}>
        {t('ownerFractionsScreen.addAFraction.page4.title')}
      </Text>

      <View style={addFractionScreenStyle.Page3Container}>
        <RewardCard
          address={`${props.form.address}, ${props.form.city} ${props.form.postCode}`}
          description={`${props.form.quantity} ${
            parseInt(props.form.quantity) == 1
              ? t('common.fractionUnit')
              : t('common.fractionUnits')
          }, ${props.form.width}cm x ${props.form.length}cm x ${props.form.height}cm`}
          reward={{
            amount: `${(props.form.isSelfDelivery
              ? parseFloat(props.deliveryReward.amount ?? '0') +
                parseFloat(props.materialReward.amount ?? '0')
              : parseFloat(props.materialReward.amount ?? '0')
            ).toFixed(2)}`,
            currency: props.materialReward.currency,
          }}
          title={props.form.typeName}
        />

        <HorizontalSpacer space={280} />

        <Text style={addFractionScreenStyle.DescriptionFont}>
          {props.form.isSelfDelivery
            ? t('ownerFractionsScreen.addAFraction.page4.descriptionSelfDelivery')
                .replace(
                  '{{amount}}',
                  props.materialReward.amount.replace('.', t('common.decimalComma')),
                )
                .replace(
                  '{{deliveryAmount}}',
                  props.deliveryReward.amount.replace('.', t('common.decimalComma')),
                )
                .replace(
                  '{{currency}}',
                  props.materialReward.amount
                    ? CurrencySymbolMap[props.materialReward.currency]
                    : '',
                )
                .replace(
                  '{{currency}}',
                  props.deliveryReward.amount
                    ? CurrencySymbolMap[props.deliveryReward.currency]
                    : '',
                )
            : t('ownerFractionsScreen.addAFraction.page4.descriptionDriverDelivery')
                .replace(
                  '{{amount}}',
                  props.materialReward.amount.replace('.', t('common.decimalComma')),
                )
                .replace(
                  '{{currency}}',
                  props.materialReward.amount
                    ? CurrencySymbolMap[props.materialReward.currency]
                    : '',
                )}
        </Text>

        <TextButton
          bgColor="primaryNavy"
          onClick={props.onPublish}
          style={addFractionScreenStyle.ContinueButton}
          textColor="white"
          title={t('ownerFractionsScreen.addAFraction.page4.publishBtn')}
          type="ROUND"
        />
      </View>
    </>
  );
}
