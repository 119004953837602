import React from 'react';
import { useFonts } from 'expo-font';
import { FontsLoad } from './src/common/styles/fonts';
import './src/common/languages/config';
import { Platform, StatusBar, View } from 'react-native';
import { Navigator } from './src/navigation/Navigator';
import { persistor, store } from './src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { HorizontalSpacer } from './src/ui/components/HorizontalSpacer/HorizontalSpacer';
import { palette } from './src/common/styles/global.style';

export default function App() {
  const [fontsLoaded] = useFonts(FontsLoad);
  if (!fontsLoaded) return <View />;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StatusBar backgroundColor={palette.white} barStyle="dark-content" />

        {Platform.OS === 'android' ? <HorizontalSpacer space={0} /> : null}

        <Navigator />
      </PersistGate>
    </Provider>
  );
}
