import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './config';
import { PaginatedRequest, PaginatedResponse } from '../models/paginated.model';
import { DisposalPointDto } from '../models/disposalPoint.model';

const LIST_DISPOSALS_API = '/v1/disposals';

export const disposalPointsApi = createApi({
  reducerPath: 'disposalPointsApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Disposals'],
  endpoints: (builder) => ({
    getAllDisposals: builder.query<PaginatedResponse<DisposalPointDto>, PaginatedRequest>({
      query: (pag) =>
        LIST_DISPOSALS_API +
        `?pageNumber=${pag?.pageNumber ?? 1}&pageSize=${pag?.pageSize ?? 10}&sortBy=${
          pag?.sortBy ?? 'id'
        }&sortDir=${pag?.sortDir ?? 'ASC'}`,
      providesTags: ['Disposals'],
    }),
  }),
});

export const disposalPointsApiReducer = disposalPointsApi.reducer;

export const { useGetAllDisposalsQuery } = disposalPointsApi;
