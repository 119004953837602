import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { flatFractionWithDelivery } from '../../../../common/utils/utils';
import {
  useArchiveDeliveryMutation,
  useDeleteFractionAndDeliveryMutation,
  useGetDeliveryQuery,
} from '../../../../redux/apis/fraction.api';
import { clearFractionsList, selectFraction } from '../../../../redux/slices/fraction.slice';
import { useFocusEffect } from '@react-navigation/native';
import { OwnerStackProps } from '../../../../navigation/OwnerTabNavigator';
import { OwnerFractionDetailsScreen } from './OwnerFractionDetailsScreen';

export function OwnerFractionDetails({ navigation }: OwnerStackProps<'OwnerFractionDetails'>) {
  const dispatch = useAppDispatch();

  const fraction = useAppSelector((state) => state.fractions.selectedFraction);
  const [refreshing, setRefreshing] = useState(false);

  const deliveryQuery = useGetDeliveryQuery(fraction.deliveryId);
  const [deleteFraction, deleteMutation] = useDeleteFractionAndDeliveryMutation();
  const [archiveFraction, archiveMutation] = useArchiveDeliveryMutation();

  useEffect(() => {
    if (deliveryQuery.isSuccess) {
      setRefreshing(false);
      const fractionWithDelivery = flatFractionWithDelivery(
        deliveryQuery.data,
        fraction.fractionId,
      );
      dispatch(selectFraction(fractionWithDelivery));
    }
  }, [deliveryQuery.data, deliveryQuery.isFetching]);

  useEffect(() => {
    if (archiveMutation.isSuccess || deleteMutation.isSuccess) {
      dispatch(clearFractionsList());
      navigation.navigate('OwnerFractions');
    }
  }, [archiveMutation, deleteMutation]);

  useFocusEffect(useCallback(() => deliveryQuery.refetch(), []));

  const onRefresh = () => {
    setRefreshing(true);
    deliveryQuery.refetch();
  };

  return (
    <OwnerFractionDetailsScreen
      fraction={fraction}
      onArchiveFraction={() => archiveFraction(fraction.deliveryId)}
      onDeleteFraction={() => deleteFraction(fraction.deliveryId)}
      onIconClick={() => navigation.goBack()}
      onRefresh={onRefresh}
      refreshing={refreshing}
    />
  );
}
