/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { palette } from './../../../common/styles/global.style';

type Props = {
  space: number | string;
  topLine?: boolean;
};

export function HorizontalSpacer(props: Props) {
  return (
    <View
      style={{
        ...style.Spacer,
        height: props.space,
        borderTopColor: palette.grey4,
        borderTopWidth: props.topLine ? 1 : 0,
      }}
    />
  );
}

const style = StyleSheet.create({
  Spacer: {
    width: '100%',
  },
});
