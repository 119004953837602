import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer } from 'redux-persist';
import { AuthData } from '../models/auth.model';
import { RootState } from '../store';

const authSlice = createSlice({
  name: 'user',
  initialState: {} as AuthData,
  reducers: {
    setAuth(state: AuthData, action: PayloadAction<AuthData>) {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      state.accessToken = action.payload.accessToken;
      state.role = action.payload.role;
      state.expiresAt = action.payload.expiresAt;
      state.rememberMe = action.payload.rememberMe;
      state.id = action.payload.id;
    },
  },
});

export const { setAuth } = authSlice.actions;

export const selectFirstName = (state: RootState) => state.auth.firstName;

export const authPersistentReducer = persistReducer(
  { key: 'auth', storage: AsyncStorage },
  authSlice.reducer,
);
