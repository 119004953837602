import React from 'react';
import { View, Text, ScrollView, SafeAreaView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ownerCurrentdeliveryStyle } from './OwnerCurrentDelivery.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, TextButton } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { getAddressString } from '../../../../common/utils/utils';

type Props = {
  fraction: FractionWithDelivery;
  onNext: () => void;
  onDelete: () => void;
  navigation: any;
};

export function OwnerCurrentDeliveryScreen1({ fraction, navigation, onNext, onDelete }: Props) {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton
          color="primaryNavy"
          onClick={() => navigation.goBack()}
          size="LARGE"
          type="PREVIOUS"
        />

        <Text style={globalStyle.TopBarFont}>{t('fractionDetailsScreen.overview')}</Text>
      </View>

      <ScrollView style={globalStyle.MainContainer}>
        <FractionCard
          description={`${fraction.quantity} ${
            fraction.quantity == 1 ? t('common.fractionUnit') : t('common.fractionUnits')
          }, ${fraction.dimensions.width}cm x ${fraction.dimensions.length}cm x ${
            fraction.dimensions.height
          }cm`}
          owner={
            !fraction.isPickedUp &&
            `${fraction.owner.firstName} ${fraction.owner.lastName.substring(0, 1).toUpperCase()}.`
          }
          pickupAddress={getAddressString(fraction.pickupAddress)}
          reward={{
            amount: `${(
              parseFloat(fraction.deliveryReward.amount) +
              parseFloat(fraction.materialReward.amount)
            ).toFixed(0)}`,
            currency: fraction.materialReward.currency,
          }}
          title={t(`fractionTypes.${fraction.type.uuid}`)}
          titleHeader={t('currentDeliveryScreen.fraction')}
        />

        <HorizontalSpacer space={8} />

        <FractionCard
          disposalPoints={[fraction.disposalPoint]}
          titleHeader={t('common.disposalSite')}
        />

        <HorizontalSpacer space={140} />
      </ScrollView>

      <View style={ownerCurrentdeliveryStyle.PickupFractionBtnContainer}>
        <TextButton
          bgColor="grey8"
          onClick={onNext}
          style={ownerCurrentdeliveryStyle.StartDrivingBtn}
          textColor="white"
          title={t('fractionDetailsScreen.startDriving')}
          type="SQUARE"
        />
        <TextButton
          bgColor="transparent"
          icon="DELETE"
          onClick={onDelete}
          style={ownerCurrentdeliveryStyle.StartDrivingBtn}
          textColor="red2"
          title={t('fractionDetailsScreen.delete')}
          type="SQUARE"
        />
      </View>
    </SafeAreaView>
  );
}
