import * as React from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import { palette } from 'common/styles/global.style';
import { iconButtonStyle } from './IconButton.style';
import { Icon } from '../Icons/Icon';

type Props = {
  type: 'PREVIOUS' | 'NEXT' | 'BELL' | 'EXIT' | 'TEXT' | 'FRACTION' | 'PROFILE' | 'HISTORY';
  text?: string;
  color: keyof typeof palette;
  reverseColor?: boolean;
  size: 'SMALL' | 'LARGE';
  notification?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  style?: any;
};

export function IconButton(props: Props) {
  const dynamicStyle = StyleSheet.create({
    Button: {
      backgroundColor: props.reverseColor ? palette[props.color] : palette.white,
      borderColor: palette[props.color],
      borderRadius: props.size == 'SMALL' ? 16 : 20,
      height: props.size == 'SMALL' ? 32 : 40,
      width: props.size == 'SMALL' ? 32 : 40,
    },
    Font: { color: props.reverseColor ? palette.white : palette[props.color] },
    Notification: {
      backgroundColor: props.reverseColor ? palette.white : palette[props.color],
      borderColor: props.reverseColor ? palette[props.color] : palette.white,
    },
  });
  const buttonStyle = StyleSheet.flatten([iconButtonStyle.Button, dynamicStyle.Button]);
  const fontStyle = StyleSheet.flatten([iconButtonStyle.Font, dynamicStyle.Font]);
  const notificationStyle = StyleSheet.flatten([
    iconButtonStyle.Notification,
    dynamicStyle.Notification,
  ]);

  const onPress = (): void => {
    if (props.onClick) props.onClick();
  };

  return (
    <View style={props.style}>
      <TouchableOpacity
        disabled={props.disabled}
        onPress={onPress}
        style={buttonStyle}
        testID={`IconButton_${props.size}_${props.type}${
          props.text ? '_' + props.text.replace(' ', '') : null
        }`}
      >
        {props.type !== 'TEXT' ? (
          <Icon color={props.color} icon={props.type} />
        ) : (
          <Text style={fontStyle}>{` ${props.text} `}</Text>
        )}

        {props.notification ? <View style={notificationStyle} /> : null}
      </TouchableOpacity>
    </View>
  );
}
