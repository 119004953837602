import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { FractionCard } from './FractionCard';
import { StatusTagType } from '../../components/StatusTag/StatusTag';
import { RewardCurrencyEnum } from '../../../redux/models/reward.model';
import { DisposalPointDto } from './../../../redux/models/disposalPoint.model';
import { ScreensDecorator } from '../../../../.storybook/screens.decorator';

storiesOf('groups/FractionCard', module)
  .addDecorator((story) => <ScreensDecorator>{story()}</ScreensDecorator>)
  .add('owner view Phase 1', () => (
    <FractionCard
      {...object('props', {
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        fractionId: '12345',
        dispoId: '54321',
        view: 'OWNER',
        title: 'Title 1',
        historyHeader: 'Published 1 min ago',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        currentPhase: 1,
        background: true,
      })}
    />
  ))
  .add('owner view Phase 2', () => (
    <FractionCard
      {...object('props', {
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        view: 'OWNER',
        title: 'Title 1',
        historyHeader: 'Published 1 min ago',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        currentPhase: 2,
        background: true,
      })}
    />
  ))
  .add('owner as a driver view', () => (
    <FractionCard
      {...object('props', {
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        view: 'OWNER_AS_DRIVER',
        title: 'Title 1',
        historyHeader: 'Published 1 min ago',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        currentPhase: 2,
        background: true,
      })}
    />
  ))
  .add('owner history', () => (
    <FractionCard
      {...object('props', {
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        title: 'Title 1',
        titleHeader: 'TODAY, 14:30',
        fractionId: '456545',
        pickupAddress: 'Mohlthanstr',
        disposalPoints: [
          { address: { streetName: 'Selmastr.1', postCode: '30000', city: ' Hamburg' } },
        ] as DisposalPointDto[],
        status: StatusTagType.REJECTED,
        background: false,
        view: 'OWNER',
      })}
    />
  ))
  .add('owner history details', () => (
    <FractionCard
      {...object('props', {
        titleHeader: 'TODAY, 14:30',
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        title: 'Title 1',
        fractionId: '456545',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        status: StatusTagType.OPEN,
        view: 'OWNER',
        background: true,
      })}
    />
  ))
  .add('driver view', () => (
    <FractionCard
      {...object('props', {
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        owner: 'Owner 1',
        title: 'Title 1',
        historyHeader: 'Published 1 min ago',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        fractionId: '1',
        view: 'DRIVER',
        background: false,
      })}
    />
  ))
  .add('driver overview', () => (
    <FractionCard
      {...object('props', {
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        owner: 'Owner 1',
        title: 'Title 1',
        titleHeader: 'Fraktion',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        view: 'DRIVER',
        background: false,
      })}
    />
  ))
  .add('driver current delivery', () => (
    <FractionCard
      {...object('props', {
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        title: 'Title 1',
        titleHeader: 'Fraktion',
        fractionId: '456545',
        owner: 'Owner 1',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        view: 'DRIVER',
        currentPhase: 1,
        background: true,
      })}
    />
  ))
  .add('driver current delivery details', () => (
    <FractionCard
      {...object('props', {
        titleHeader: 'Fraktion',
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        title: 'Title 1',
        fractionId: '456545',
        owner: 'Owner 1',
        description: 'dimensions',
        pickupAddress: 'Mohlthanstr',
        view: 'DRIVER',
        currentPhase: 3,
        background: true,
      })}
    />
  ))
  .add('driver history', () => (
    <FractionCard
      {...object('props', {
        titleHeader: 'TODAY, 14:30',
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        status: StatusTagType.SUCCESSFUL,
        title: 'Title 1',
        fractionId: '456545',
        pickupAddress: 'Mohlthanstr',
        disposalPoints: [
          { address: { streetName: 'Selmastr.1', postCode: '30000', city: ' Hamburg' } },
        ] as DisposalPointDto[],
        view: 'DRIVER',
        background: false,
      })}
    />
  ))
  .add('driver history details', () => (
    <FractionCard
      {...object('props', {
        titleHeader: 'TODAY, 14:30',
        reward: { amount: '200', currency: RewardCurrencyEnum.EUR },
        status: StatusTagType.OPEN,
        title: 'Title 1',
        pickupAddress: 'Mohlthanstr',
        disposalPoints: [
          { address: { streetName: 'Selmastr.1', postCode: '30000', city: ' Hamburg' } },
        ] as DisposalPointDto[],
        view: 'DRIVER',
        background: false,
      })}
    />
  ))
  .add('simple disposal site', () => (
    <FractionCard
      {...object('props', {
        titleHeader: 'DISPOSAL SITE',
        disposalPoints: [
          { address: { streetName: 'Selmastr.1', postCode: '30000', city: ' Hamburg' } },
          { address: { streetName: 'Mohlthanstr.2', postCode: '30000', city: ' Hamburg' } },
        ] as DisposalPointDto[],
        background: false,
      })}
    />
  ));
