import React from 'react';
import { View, Text, SafeAreaView, FlatList, RefreshControl } from 'react-native';
import { useTranslation } from 'react-i18next';
import { globalStyle } from '../../../common/styles/global.style';
import { IconButton } from '../../components';
import { FractionCard } from '../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../components/HorizontalSpacer/HorizontalSpacer';
import { FractionWithDelivery } from '../../../redux/models/fraction.model';
import { getAddressString, getRewardByRole } from '../../../common/utils/utils';
import { StatusTagType } from '../../components/StatusTag/StatusTag';
import moment from 'moment';
import { RoleNameEnum } from '../../../redux/models/role.model';

type Prop = {
  fractions: FractionWithDelivery[];
  role: RoleNameEnum;
  refreshing?: boolean;
  onSelect?: (fraction: FractionWithDelivery) => void;
  onEndReached?: () => void;
  onRefresh?: () => void;
};

export function HistoryScreen({
  fractions,
  role,
  refreshing,
  onRefresh,
  onSelect,
  onEndReached,
}: Prop) {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" size="LARGE" type="HISTORY" />

        <Text style={globalStyle.TopBarFont}>{t('historyScreen.history')}</Text>
      </View>

      <FlatList
        ListFooterComponent={<HorizontalSpacer space={20} />}
        data={fractions}
        keyExtractor={(item) => item.id.toString()}
        onEndReached={onEndReached}
        refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={refreshing} />}
        renderItem={({ item }: { item: FractionWithDelivery }) => (
          <View>
            <View key={`Fraction_${item.id}`} testID={`Fraction_${item.id}`}>
              <FractionCard
                dispoId={item.isInterZeroDelivery ? item.interZeroId.toString() : null}
                disposalPoints={[item.disposalPoint]}
                fractionId={item.uuid.substring(0, 5)}
                onClick={() => onSelect(item)}
                pickupAddress={getAddressString(item.pickupAddress)}
                reward={getRewardByRole(item, role)}
                status={
                  item.isInterZeroDeliveryError
                    ? StatusTagType.REJECTED
                    : ((role == RoleNameEnum.OWNER ? item.materialReward : item.deliveryReward)
                        .statusName as unknown as StatusTagType)
                }
                title={t(`fractionTypes.${item.type.uuid}`)}
                titleHeader={moment(item.dateCreated).format(t('time.datetimeFormat'))}
              />

              <HorizontalSpacer space={10} />
            </View>
          </View>
        )}
      />
    </SafeAreaView>
  );
}
