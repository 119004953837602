import { StyleSheet } from 'react-native';
import { palette } from '../../../common/styles/global.style';

export const keyboardAvoiderStyle = StyleSheet.create({
  MainContainer: {
    backgroundColor: palette.white,
    height: '100%',
    width: '100%',
  },
});
