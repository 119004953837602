import { FractionAddDto } from '../../redux/models/fraction.model';
import { AddFractionForm } from '../../ui/screens/OwnerScreens/AddFraction/AddFraction';

export function mapAddFrationFormToDto(form: AddFractionForm): FractionAddDto {
  return {
    isSelfDelivery: form.isSelfDelivery,
    dimensions: {
      height: parseInt(form.height),
      width: parseInt(form.width),
      length: parseInt(form.length),
    },
    quantity: parseInt(form.quantity),
    pickupAddress: {
      name: `${form.address}, ${form.city} ${form.postCode}, Germany`,
      streetName: form.address,
      city: form.city,
      country: 'Germany',
      postCode: form.postCode,
    },
    disposalPointId: form.disposalPointId,
    fractionTypeId: parseInt(form.type),
  };
}
