import { StyleSheet } from 'react-native';
import { Fonts } from '../../../../common/styles/fonts';
import { palette } from '../../../../common/styles/global.style';

export const addFractionScreenStyle = StyleSheet.create({
  CentralContainer: {
    alignItems: 'center',
    minHeight: '100%',
    paddingTop: 10,
    width: '100%',
    zIndex: 3,
  },
  ContinueButton: {
    alignSelf: 'flex-end',
    flex: 1,
    marginBottom: 36,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 56,
  },
  DescriptionFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginLeft: 40,
    marginRight: 40,
    marginTop: 16,
  },
  DimentionUnitContainer: {
    flexDirection: 'row',
  },
  FormContainer: {
    paddingHorizontal: 40,
    paddingTop: 25,
    width: '100%',
  },
  InputField: {
    flex: 1,
    marginTop: 16,
  },
  Page3Container: {
    alignContent: 'flex-start',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    maxWidth: 450,
    paddingTop: 30,
    width: '100%',
  },
  ScrollContainer: {
    backgroundColor: palette.white,
    height: '100%',
    width: '100%',
    zIndex: 0,
  },
  TitleFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 56,
    marginTop: 13,
    paddingHorizontal: 24,
  },
  UnitDropdown: {
    bottom: 0,
    flex: 1,
    left: 0,
    marginLeft: 16,
    marginTop: 47,
    maxWidth: 81,
  },
});
