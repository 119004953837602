export enum Fonts {
  FranklinGothicBook = 'FranklinGothicBook',
  FranklinGothicBookIt = 'FranklinGothicBookIt',
  FranklinGothicDemi = 'FranklinGothicDemi',
  FranklinGothicDemiCn = 'FranklinGothicDemiCn',
  FranklinGothicDemiIt = 'FranklinGothicDemiIt',
  FranklinGothicHeavy = 'FranklinGothicHeavy',
  FranklinGothicHeavyIt = 'FranklinGothicHeavyIt',
  FranklinGothicMedium = 'FranklinGothicMedium',
  FranklinGothicMediumCn = 'FranklinGothicMediumCn',
  FranklinGothicMediumIt = 'FranklinGothicMediumIt',
}

export const FontsLoad = {
  [Fonts.FranklinGothicBook]: require('../../assets/font/FRABK.ttf'),
  [Fonts.FranklinGothicBookIt]: require('../../assets/font/FRABKIT.ttf'),
  [Fonts.FranklinGothicDemi]: require('../../assets/font/FRADM.ttf'),
  [Fonts.FranklinGothicDemiCn]: require('../../assets/font/FRADMCN.ttf'),
  [Fonts.FranklinGothicDemiIt]: require('../../assets/font/FRADMIT.ttf'),
  [Fonts.FranklinGothicHeavy]: require('../../assets/font/FRAHV.ttf'),
  [Fonts.FranklinGothicHeavyIt]: require('../../assets/font/FRAHVIT.ttf'),
  [Fonts.FranklinGothicMedium]: require('../../assets/font/FRAMD.ttf'),
  [Fonts.FranklinGothicMediumCn]: require('../../assets/font/FRAMDCN.ttf'),
  [Fonts.FranklinGothicMediumIt]: require('../../assets/font/FRAMDIT.ttf'),
};
