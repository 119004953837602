import React from 'react';
import { View, Text, ImageBackground, SafeAreaView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { profileScreenStyle } from './ProfileScreen.style';
import { IconButton, TextButton } from '../../components';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { globalStyle } from '../../../common/styles/global.style';
import { resetAllNonPersistentStates } from '../../../redux/store';
import { setAuth } from '../../../redux/slices/auth.slice';

export function ProfileScreen() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.auth);

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" size="LARGE" type="PROFILE" />

        <Text style={globalStyle.TopBarFont}>{t('profileScreen.profile')}</Text>
      </View>

      <View style={profileScreenStyle.MainContainer}>
        <ImageBackground
          source={require('../../../assets/background/FractionCardBg.png')}
          style={profileScreenStyle.Background}
        />

        <Text style={profileScreenStyle.LabelFont}>{t('profileScreen.name')}</Text>

        <Text style={profileScreenStyle.ValueFont}>
          {`${authUser.firstName} ${authUser.lastName}`}
        </Text>

        <Text style={profileScreenStyle.LabelFont}>{t('profileScreen.phoneNumber')}</Text>

        <Text style={profileScreenStyle.ValueFont}>{authUser.mobile}</Text>

        <Text style={profileScreenStyle.LabelFont}>{t('profileScreen.email')}</Text>

        <Text style={profileScreenStyle.ValueFont}>{authUser.email}</Text>
      </View>

      <View style={profileScreenStyle.SignOutButtonContainer}>
        <TextButton
          bgColor="grey8"
          onClick={() => {
            dispatch(setAuth({}));
            dispatch(resetAllNonPersistentStates());
          }}
          style={profileScreenStyle.SignOutButton}
          textColor="white"
          title={t('profileScreen.signOut')}
          type="SQUARE"
        />
      </View>
    </SafeAreaView>
  );
}
