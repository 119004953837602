import { StyleSheet } from 'react-native';
import { palette } from 'common/styles/global.style';

export const modalStyles = StyleSheet.create({
  Button: {
    borderRadius: 8,
    height: 40,
    marginHorizontal: 5,
    width: 108,
  },
  Main: {
    backgroundColor: palette.transparentGrey,
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  Message: {
    fontSize: 24,
    fontWeight: '400',
    lineHeight: 32,
  },
  Modal: {
    backgroundColor: palette.white,
    borderRadius: 16,
    left: 16,
    minHeight: 268,
    position: 'absolute',
    top: 258,
    width: '90%',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 46,
  },
  messageContainer: {
    padding: 42,
    textAlign: 'center',
  },
});
