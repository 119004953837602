import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetAllDeliveriesQuery, useGetCurrentDeliveriesQuery } from 'redux/apis/fraction.api';
import {
  addFractionsToPagedList,
  clearCurrentDelivery,
  clearFractionsList,
  getFractionsList,
  nextFractionsPage,
  selectFraction,
  setCurrentDelivery,
} from 'redux/slices/fraction.slice';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { flatFractionsWithDelivery } from '../../../../common/utils/utils';
import { useFocusEffect } from '@react-navigation/native';
import { DriverStackProps } from '../../../../navigation/DriverTabNavigator';
import { DriverFractionsScreen } from './DriverFractionsScreen';

const pageSize = 10;

export function DriverFractions({ navigation }: DriverStackProps<'DriverFractions'>) {
  const dispatch = useAppDispatch();

  const [refreshing, setRefreshing] = useState(false);
  const fractions = useAppSelector(getFractionsList);
  const page = useAppSelector((state) => state.fractions.page);

  const deliveriesQuery = useGetAllDeliveriesQuery({ pageNumber: page, pageSize, sortDir: 'DESC' });

  useEffect(() => {
    if (deliveriesQuery.isSuccess) {
      setRefreshing(false);
      const fractionsWithDelivery = flatFractionsWithDelivery(deliveriesQuery.data.data);
      dispatch(addFractionsToPagedList({ page, fractions: fractionsWithDelivery }));
    }
  }, [deliveriesQuery.data, deliveriesQuery.isFetching]);

  const currentDeliveries = useGetCurrentDeliveriesQuery(undefined);

  useEffect(() => {
    if (
      !currentDeliveries.isFetching &&
      currentDeliveries.isSuccess &&
      currentDeliveries.data.totalElements > 0
    ) {
      dispatch(setCurrentDelivery(flatFractionsWithDelivery(currentDeliveries.data.data)[0])); //Consider only one possible current delivery
      navigation.navigate('DriverCurrentDelivery');
    }
  }, [currentDeliveries.data, currentDeliveries.isFetching]);

  useFocusEffect(
    useCallback(() => {
      dispatch(clearCurrentDelivery());
      currentDeliveries.refetch();
    }, []),
  );

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(clearFractionsList());
    deliveriesQuery.refetch();
  };

  const handleSelect = (fraction: FractionWithDelivery) => {
    dispatch(selectFraction(fraction));
    navigation.navigate('DriverFractionDetails');
  };

  return (
    <DriverFractionsScreen
      fractions={fractions}
      onEndReached={() => page < deliveriesQuery.data.totalPages && dispatch(nextFractionsPage())}
      onRefresh={handleRefresh}
      onSelect={handleSelect}
      refreshing={refreshing}
    />
  );
}
