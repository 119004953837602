import * as React from 'react';
import { TouchableOpacity, View, Image } from 'react-native';
import { profilePictureStyle } from './ProfilePicture.style';

type Props = {
  imageUri: string;
  notification?: boolean;
  onClick?: () => void;
  style?: any;
};

export function ProfilePicture(props: Props) {
  const onPress = (): void => {
    if (props.onClick) props.onClick();
  };

  return (
    <TouchableOpacity onPress={onPress} style={props.style} testID="ProfilePicture">
      <Image source={{ uri: props.imageUri }} style={profilePictureStyle.Picture} />

      {props.notification ? <View style={profilePictureStyle.Notification} /> : null}
    </TouchableOpacity>
  );
}
