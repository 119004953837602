import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  strokeWidth?: number;
  dotted?: boolean;
  xmlns?: string;
  style?: any;
};

export function VerticalLine(props: Props) {
  return (
    <Svg
      color={props.color}
      height={props.height}
      style={props.style}
      width={6}
      //xmlns={props.xmlns ?? 'http://www.w3.org/2000/svg'}
    >
      <Path
        d={`M 3,0 V ${props.height}`}
        stroke={props.color}
        strokeDasharray={props.dotted ? '5,3' : '0'}
        strokeWidth={props.strokeWidth || 1}
      />
    </Svg>
  );
}
