import * as React from 'react';
import { StyleSheet, TextInput, Text, View } from 'react-native';
import { globalStyle, palette } from '../../../common/styles/global.style';
import { ValidationObj } from '../../../common/utils/fieldValidator';
import { InputBoxStyle } from './InputBox.style';

type Props = {
  label?: string;
  placeholder?: string;
  isPassword?: boolean;
  validations?: ValidationObj | ValidationObj[];
  maxLength?: number;
  style?: any;
  numbersOnly?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (value?: string) => void;
  onError?: (msg?: string) => void;
};

export function InputBox(props: Props) {
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [value, setValue] = React.useState(props.value || '');

  React.useEffect(() => {
    props.onChange ? props.onChange(value) : null;
  }, [value]);

  const dynamicStyle = {
    borderColor: errorMsg ? palette.red2 : palette.grey3,
    color: props.disabled ? palette.grey5 : palette.grey7,
  };
  const inputFieldStyle = StyleSheet.flatten([InputBoxStyle.InputField, dynamicStyle]);

  const validate = () => {
    if (props.validations) {
      let valid;
      const validations = Array.isArray(props.validations)
        ? props.validations
        : [props.validations];
      for (const validation of validations) {
        valid = validation.condition(value);
        if (!valid) {
          props.onError ? props.onError(validation.msg) : null;
          return setErrorMsg(validation.msg);
        }
      }
      props.onError ? props.onError('') : null;
      return setErrorMsg(null);
    }
  };

  return (
    <View style={props.style}>
      {props.label ? (
        <Text
          style={globalStyle.FontSmall}
          testID={`TextInput_Label_${props.label.replace(' ', '')}`}
        >
          {props.label}
        </Text>
      ) : null}

      <TextInput
        editable={!props.disabled}
        keyboardType={props.numbersOnly ? 'number-pad' : 'default'}
        maxLength={props.maxLength}
        onBlur={validate}
        onChangeText={setValue}
        placeholder={props.placeholder}
        secureTextEntry={props.isPassword}
        style={inputFieldStyle}
        testID={`TextInput_${props.label}`}
        value={value || ''}
      />

      {errorMsg ? (
        <Text style={globalStyle.ErrorFont} testID={`TextInput_Error_${props.label}`}>
          {errorMsg}
        </Text>
      ) : null}
    </View>
  );
}
