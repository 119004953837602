export type ValidationObj = {
  condition: (value?: string) => boolean;
  msg: string;
};

export function isEmail(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      !value ? false : value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) ? true : false,
    msg: customMsg || 'Invalid email address',
  };
}

export function isNotEmpty(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) => (value && value != '' ? true : false),
    msg: customMsg || 'Field is required',
  };
}

export function isNumber(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      value ? (value.match(/^\d*\.?\d+$/) ? true : false) : true,
    msg: customMsg || 'Field must be a number',
  };
}

export function isOnlyLettersAndSpaces(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      value ? (value.match(/^[a-zA-Z\s]*$/) ? true : false) : true,
    msg: customMsg || 'Field must be letters only',
  };
}

export function maxLength(length: number, customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) => (value ? (value.length <= length ? true : false) : true),
    msg: customMsg || `Max legth must be ${length}`,
  };
}

export function minLength(length: number, customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) => (value ? (value.length >= length ? true : false) : true),
    msg: customMsg || `Min legth must be ${length}`,
  };
}

export function min(min: number, customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) => (value ? (parseFloat(value) > min ? true : false) : true),
    msg: customMsg || `Value must be greater than ${min}`,
  };
}
