import React from "react";
import { Text, View } from "react-native";
import { storybookStyle } from "./storybook.style";


export const ScreensDecorator = ({ children }) => (
  <View style={{display:"flex", flexDirection:"row"}}>
      <Text>360x800</Text>
      <View style={storybookStyle.StorybookMobileScreen1}>
          {children}
      </View>

      <Text>360x640</Text>

      <View style={storybookStyle.StorybookMobileScreen2}>
        {children}
      </View>

      <Text>414x896</Text>

      <View style={storybookStyle.StorybookMobileScreen3}>
        {children}
      </View>

      <Text>390x844</Text>

      <View style={storybookStyle.StorybookMobileScreen4}>
        {children}
      </View>

      <Text>412x915</Text>

      <View style={storybookStyle.StorybookMobileScreen5}>
        {children}
      </View>

      <Text>720x1280</Text>

      <View style={storybookStyle.StorybookMobileScreen6}>
        {children}
      </View>
  </View>
)