import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { ScreensDecorator } from '../../../../../.storybook/screens.decorator';
import { DriverCurrentDeliveryScreen1 } from './DriverCurrentDeliveryScreen1';
import { DriverCurrentDeliveryScreen2 } from './DriverCurrentDeliveryScreen2';
import { DriverCurrentDeliveryScreen3 } from './DriverCurrentDeliveryScreen3';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { PhasesEnum } from '../../../../redux/models/phase.model';

const fraction: FractionWithDelivery = {
  type: {
    name: 'Fraction type',
  },
  id: 1,
  uuid: 'asfdasdfa',
  owner: {
    firstName: 'John',
    lastName: 'Doe',
  },
  pickupAddress: {
    streetName: 'Street 2',
    city: 'Las Vegas',
    postCode: '99999',
  },
  dateCreated: new Date(),
  datePublished: new Date(),
  materialReward: {
    amount: '200',
    currency: 'EUR',
  },
  quantity: 7,
  dimensions: {
    width: 1,
    height: 2,
    length: 3,
  },
  transporterPhase: {
    currentPhaseId: 1,
    currentPhaseName: PhasesEnum.T_PICKUP,
    phases: [
      {
        id: 1,
        name: PhasesEnum.T_PICKUP,
        dateStarted: new Date(),
        order: 1,
      },
    ],
  },
  deliveryReward: {
    amount: '50',
    currency: 'EUR',
  },
  disposalPoint: {
    address: {
      streetName: 'Street 7',
      city: 'Berlin',
      postCode: '12345',
    },
  },
  isSelfDelivery: false,
  fractionId: 1,
  deliveryId: 1,
} as FractionWithDelivery;

storiesOf('screens/DriverCurrentDelivery', module)
  .addDecorator((story) => <ScreensDecorator>{story()}</ScreensDecorator>)
  .add('pickUp', () => <DriverCurrentDeliveryScreen1 fraction={fraction} onNext={() => void 0} />)
  .add('deliver', () => <DriverCurrentDeliveryScreen2 fraction={fraction} onNext={() => void 0} />)
  .add('finish', () => <DriverCurrentDeliveryScreen3 fraction={fraction} onNext={() => void 0} />);
