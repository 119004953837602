/* eslint-disable react/no-multi-comp */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';
import { globalStyle } from '../common/styles/global.style';
import { DriverFractions } from '../ui/screens/DriverScreens/DriverFractions/DriverFractions';
import { ProfileScreen } from '../ui/screens/ProfileScreen/ProfileScreen';
import { DriverFractionDetails } from '../ui/screens/DriverScreens/DriverFractionDetails/DriverFractionDetails';
import { DriverCurrentDelivery } from '../ui/screens/DriverScreens/DriverCurrentDelivery/DriverCurrentDelivery';
import { useAppSelector } from '../redux/hooks';
import { CompositeScreenProps } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackScreens } from './Navigator';
import { OwnerTabScreens } from './OwnerTabNavigator';
import { StackScreenProps } from '@react-navigation/stack';
import { fractionsNavBtn, historyNavBtn, profileNavBtn } from './navigationButtons';
import { History } from '../ui/screens/HistoryScreen/History';
import {HistoryDetails} from 'ui/screens/HistoryScreen/HistoryDetails/HistoryDetails';

export type DriverTabScreens = {
  DriverFractions: undefined;
  DriverFractionDetails: undefined;
  DriverCurrentDelivery: undefined;
  History: undefined;
  HistoryDetails: undefined;
  ProfileScreen: undefined;
};

export type DriverStackProps<T extends keyof DriverTabScreens> = CompositeScreenProps<
  NativeStackScreenProps<DriverTabScreens, T>,
  CompositeScreenProps<StackScreenProps<RootStackScreens>, StackScreenProps<OwnerTabScreens>>
>;

const Tab = createBottomTabNavigator<DriverTabScreens>();

export function DriverTabNavigator() {
  const currentDelivery = useAppSelector((state) => state.fractions.currentDelivery);

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: globalStyle.NavBarContainer,
        headerShown: false,
      }}
    >
      <Tab.Screen
        component={DriverFractions}
        name="DriverFractions"
        options={{
          tabBarTestID: 'Tab_Btn_Fractions',
          tabBarButton: (data) => (currentDelivery ? null : fractionsNavBtn(data)),
        }}
      />

      <Tab.Screen
        component={DriverFractionDetails}
        name="DriverFractionDetails"
        options={{ tabBarButton: () => null }}
      />

      <Tab.Screen
        component={DriverCurrentDelivery}
        name="DriverCurrentDelivery"
        options={{
          tabBarTestID: 'Tab_Btn_Fractions',
          tabBarButton: (data) => (!currentDelivery ? null : fractionsNavBtn(data)),
        }}
      />

      <Tab.Screen
        component={History}
        name="History"
        options={{
          tabBarTestID: 'Tab_Btn_History',
          tabBarButton: historyNavBtn,
          tabBarHideOnKeyboard: false,
        }}
      />
      <Tab.Screen
        component={HistoryDetails}
        name="HistoryDetails"
        options={{ tabBarButton: () => null }}
      />

      <Tab.Screen
        component={ProfileScreen}
        name="ProfileScreen"
        options={{
          tabBarTestID: 'Tab_Btn_Profile',
          tabBarButton: profileNavBtn,
        }}
      />
    </Tab.Navigator>
  );
}
