import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: any;
};

export function HistoryIcon(props: Props) {
  return (
    <Svg
      fill="none"
      height={props.height}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M14.4 0H4.8c-.88 0-1.6.72-1.6 1.6v9.6c0 .88.72 1.6 1.6 1.6h9.6c.88 0 1.6-.72 1.6-1.6V1.6c0-.88-.72-1.6-1.6-1.6ZM7.808 9.024 6.152 7.36a.802.802 0 0 1 0-1.12.797.797 0 0 1 1.128 0l1.088 1.096 3.536-3.568a.797.797 0 0 1 1.128 0 .802.802 0 0 1 0 1.12L8.928 9.024a.773.773 0 0 1-1.12 0ZM.8 3.2c-.44 0-.8.36-.8.8v10.4c0 .88.72 1.6 1.6 1.6H12c.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8H2.4c-.44 0-.8-.36-.8-.8V4c0-.44-.36-.8-.8-.8Z"
        fill={props.color}
        scale={props.height / 16}
      />
    </Svg>
  );
}
