import * as React from 'react';
import { View, Text, ImageBackground, TouchableOpacity, StyleSheet } from 'react-native';
import { TropheeIcon } from '../../components/Icons/TropheeIcon';
import { fractionCardStyle } from './FractionCard.style';
import { palette } from 'common/styles/global.style';
import { PhaseDisplay, StatusTag } from '../../components';
import { HorizontalSpacer } from '../../components/HorizontalSpacer/HorizontalSpacer';
import { Icon } from '../../components/Icons/Icon';
import { useTranslation } from 'react-i18next';
import { StatusTagType } from '../../components/StatusTag/StatusTag';
import {
  OWNER_AS_DRIVER_PHASES,
  OWNER_PHASES,
  DRIVER_PHASES,
} from '../../../common/utils/constants';
import { useEffect } from 'react';
import { DisposalPointDto } from './../../../redux/models/disposalPoint.model';
import { getAddressString } from '../../../common/utils/utils';
import { RewardDto, CurrencySymbolMap } from '../../../redux/models/reward.model';

type Props = {
  background?: boolean;
  historyHeader?: string;
  titleHeader?: string;
  reward?: RewardDto;
  status?: StatusTagType;
  title?: string;
  fractionId?: string;
  dispoId?: string;
  owner?: string;
  ownerMobile?: string;
  description?: string;
  pickupAddress?: string;
  disposalPoints?: DisposalPointDto[];
  view?: 'OWNER' | 'OWNER_AS_DRIVER' | 'DRIVER';
  currentPhase?: number;
  onChange?: (disposalPointId: number) => void;
  onClick?: () => void;
};

export function FractionCard(props: Props) {
  const { t } = useTranslation();

  const [disposalPoint, setDisposalPoint] = React.useState<DisposalPointDto>();

  useEffect(() => {
    setDisposalPoint(props.disposalPoints?.[0]);
  }, [props.disposalPoints]);

  useEffect(() => {
    props.onChange ? props.onChange(disposalPoint?.id) : null;
  }, [disposalPoint]);

  const phases = () => {
    switch (props.view) {
      case 'OWNER':
        return Object.values(OWNER_PHASES);
      case 'OWNER_AS_DRIVER':
        return Object.values(OWNER_AS_DRIVER_PHASES);
      case 'DRIVER':
        return Object.values(DRIVER_PHASES);
      default:
        return ['missing phases'];
    }
  };

  const optionsButtons = props.disposalPoints?.map((dp, i) => (
    <TouchableOpacity
      key={`OptionButton_${i + 1}`}
      onPress={() => setDisposalPoint(dp)}
      style={StyleSheet.flatten([
        fractionCardStyle.OptionButton,
        {
          backgroundColor: dp?.id == disposalPoint?.id ? palette.grey8 : palette.white,
        },
      ])}
      testID={`OptionButton_${i + 1}`}
    >
      <Text
        style={StyleSheet.flatten([
          fractionCardStyle.OptionButtonFont,
          {
            color: dp?.id == disposalPoint?.id ? palette.white : palette.grey7,
          },
        ])}
      >
        {`${t('ui.groups.fractionCard.option')} ${i + 1}`}
      </Text>
    </TouchableOpacity>
  ));

  return (
    <View
      style={fractionCardStyle.MainContainer}
      testID={`FractionCard_${props.fractionId ? props.fractionId : 'noId'}`}
    >
      {props.onClick ? (
        <TouchableOpacity onPress={props.onClick} style={fractionCardStyle.CardAsButton} />
      ) : null}

      {props.background ? (
        <ImageBackground
          source={require('../../../assets/background/FractionCardBg.png')}
          style={fractionCardStyle.Background}
        />
      ) : null}

      <View style={fractionCardStyle.HeaderContainer}>
        {props.historyHeader ? (
          <Text style={fractionCardStyle.HistoryFont}>{props.historyHeader}</Text>
        ) : (
          <Text />
        )}

        {props.titleHeader ? (
          <Text style={fractionCardStyle.TitleHeaderFont}>{props.titleHeader}</Text>
        ) : null}

        {props.reward ? (
          <View style={fractionCardStyle.Badge}>
            <TropheeIcon
              color={palette.accentMustard}
              height={16}
              style={fractionCardStyle.Trophee}
            />

            <Text style={fractionCardStyle.BadgeFont}>
              {props.reward.amount
                ? `${CurrencySymbolMap[props.reward.currency]} ${parseFloat(
                    props.reward.amount,
                  ).toFixed(0)}`
                : ''}
            </Text>
          </View>
        ) : null}
      </View>

      {props.status ? (
        <View style={fractionCardStyle.StatusTagContainer}>
          <StatusTag status={props.status} />
        </View>
      ) : null}

      {props.title ? <Text style={fractionCardStyle.TitleFont}>{props.title}</Text> : null}

      {props.owner ? (
        <>
          <View style={fractionCardStyle.DescriptionContainer}>
            <Text style={fractionCardStyle.DescriptionFont}>
              {t('ui.groups.fractionCard.publishedBy') + ' '}
            </Text>

            <Text style={fractionCardStyle.OwnerFont}>{props.owner}</Text>
          </View>

          {props.ownerMobile ? (
            <View style={fractionCardStyle.DescriptionContainer}>
              <View style={fractionCardStyle.Icon}>
                <Icon color="grey7" icon="TELEPHONE" style={fractionCardStyle.LocationIcon} />
              </View>

              <Text style={fractionCardStyle.DescriptionFont}>{props.ownerMobile}</Text>
            </View>
          ) : null}

          <HorizontalSpacer space={12} />
        </>
      ) : null}

      {props.description ? (
        <View style={fractionCardStyle.DescriptionContainer}>
          <View style={fractionCardStyle.Icon}>
            <Icon color="grey7" icon="DIMENSION" />
          </View>

          <Text style={fractionCardStyle.DescriptionFont}>{props.description}</Text>
        </View>
      ) : null}

      {props.pickupAddress ? (
        <View style={fractionCardStyle.DescriptionContainer}>
          <View style={fractionCardStyle.Icon}>
            <Icon color="grey7" icon="LOCATION" style={fractionCardStyle.LocationIcon} />
          </View>

          <Text style={fractionCardStyle.DescriptionFont}>{props.pickupAddress}</Text>
        </View>
      ) : null}

      {props.fractionId ? (
        <>
          <HorizontalSpacer space={16} />
          <HorizontalSpacer space={14} topLine />
          <View style={fractionCardStyle.FractionIdContainer}>
            <Text style={fractionCardStyle.FractionIdFont}>
              {t('ui.groups.fractionCard.fractionId')}
            </Text>
            <Text style={fractionCardStyle.FractionIdFont2}>
              {'#' + props.fractionId.toString()}
            </Text>
          </View>
        </>
      ) : null}

      {props.dispoId ? (
        <View style={fractionCardStyle.FractionIdContainer}>
          <Text style={fractionCardStyle.FractionIdFont}>
            {t('ui.groups.fractionCard.dispoId')}
          </Text>
          <Text style={fractionCardStyle.FractionIdFont2}>{'#' + props.dispoId.toString()}</Text>
        </View>
      ) : null}

      {props.disposalPoints && props.disposalPoints.length > 1 ? (
        <View style={fractionCardStyle.OptionsContainer}>{optionsButtons}</View>
      ) : null}

      {props.disposalPoints ? (
        <View style={fractionCardStyle.DescriptionContainer}>
          <View style={fractionCardStyle.Icon}>
            <Icon color="grey7" icon="RECYCLE" />
          </View>

          <Text style={fractionCardStyle.DescriptionFont}>
            {disposalPoint ? getAddressString(disposalPoint.address) : null}
          </Text>
        </View>
      ) : null}

      {props.currentPhase ? (
        <>
          <HorizontalSpacer space={16} />

          <PhaseDisplay currentPhase={props.currentPhase} phaseDescriptions={phases()} />
        </>
      ) : null}
    </View>
  );
}
