import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { palette } from 'common/styles/global.style';
import { navigationButtonStyle } from './NavigationButton.style';
import { Icon } from '../Icons/Icon';

type Props = {
  selected: boolean;
  color: keyof typeof palette;
  label: string;
  disabled?: boolean;
  icon: 'FRACTION' | 'HISTORY' | 'PROFILE';
  onClick?: (any?: any) => void;
};

export function NavigationButton(props: Props) {
  const dynamicStyle = StyleSheet.create({
    Button: {
      borderTopColor: props.selected ? palette[props.color] : palette.white,
    },
  });
  const buttonStyle = StyleSheet.flatten([navigationButtonStyle.Button, dynamicStyle.Button]);
  const textStyle = StyleSheet.flatten([
    navigationButtonStyle.Font,
    { color: props.selected ? palette.grey7 : palette.grey5 },
  ]);

  const onPress = (): void => {
    if (props.onClick) props.onClick();
  };

  const icon = <Icon color={props.selected ? props.color : 'grey5'} icon={props.icon} />;

  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={onPress}
      style={buttonStyle}
      testID={`NavigationButton_${props.icon}_${props.label.replace(' ', '')}`}
    >
      {icon}

      <Text style={textStyle}>{props.label}</Text>
    </TouchableOpacity>
  );
}
