
import { StyleSheet } from "react-native";
import { palette } from '../src/common/styles/global.style';


export const storybookStyle = StyleSheet.create({
  StorybookMobileScreen1: {
    backgroundColor: "#FFFF",
    borderColor: "#505050",
    borderStyle: 'solid',
    borderWidth: 1,
    height: 800,
    width: 360
  },
  StorybookMobileScreen2: {
    backgroundColor: "#FFFF",
    borderColor: "#505050",
    borderStyle: 'solid',
    borderWidth: 1,
    height: 640,
    width: 360
  },
  StorybookMobileScreen3: {
    backgroundColor: "#FFFF",
    borderColor: "#505050",
    borderStyle: 'solid',
    borderWidth: 1,
    height: 896,
    width: 414
  },
  StorybookMobileScreen4: {
    backgroundColor: "#FFFF",
    borderColor: "#505050",
    borderStyle: 'solid',
    borderWidth: 1,
    height: 844,
    width: 390
  },
  StorybookMobileScreen5: {
    backgroundColor: "#FFFF",
    borderColor: "#505050",
    borderStyle: 'solid',
    borderWidth: 1,
    height: 915,
    width: 412
  },
  StorybookMobileScreen6: {
    backgroundColor: "#FFFF",
    borderColor: "#505050",
    borderStyle: 'solid',
    borderWidth: 1,
    height: 1280,
    width: 720
  },
});
