import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet } from 'react-native';
import { statusTagStyle } from './StatusTag.style';
import { palette } from 'common/styles/global.style';

export enum StatusTagType {
  REJECTED = 'REJECTED',
  OPEN = 'OPEN',
  SUCCESSFUL = 'SUCCESSFUL',
}

type Props = {
  status: StatusTagType;
};

export function StatusTag(props: Props) {
  const { t } = useTranslation();

  switch (props.status) {
    case StatusTagType.OPEN:
      return (
        <Text
          style={StyleSheet.flatten([statusTagStyle.Main, { borderColor: palette.accentMustard }])}
        >
          {t('ui.components.statusTag.awaitingPayment')}
        </Text>
      );
    case StatusTagType.SUCCESSFUL:
      return (
        <Text
          style={StyleSheet.flatten([statusTagStyle.Main, { borderColor: palette.successGreen1 }])}
        >
          {t('ui.components.statusTag.paymentReceived')}
        </Text>
      );
    case StatusTagType.REJECTED:
      return (
        <Text style={StyleSheet.flatten([statusTagStyle.Main, { borderColor: palette.red2 }])}>
          {t('ui.components.statusTag.cancelled')}
        </Text>
      );
    default:
      return <Text style={statusTagStyle.Main}> </Text>;
  }
}
