import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { InputBox, TextButton } from '../../../components';
import { addFractionScreenStyle } from './AddFraction.style';
import { isNotEmpty, isNumber } from '../../../../common/utils/fieldValidator';
import { minLength } from '../../../../common/utils/fieldValidator';

export type AddFractionForm2 = {
  address: string;
  city: string;
  postCode: string;
};

type Props = {
  form: AddFractionForm2;
  onChange?: (form?: AddFractionForm2) => void;
  onContinue: (form?: AddFractionForm2) => void;
};

export function AddFractionScreen2(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState(props.form);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    props.onChange ? props.onChange(form) : null;
  }, [form]);

  const handleChange = (name: keyof AddFractionForm2, value: string) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const handleError = (name: keyof AddFractionForm2, errorMsg: string) => {
    setErrors((errors) => ({ ...errors, [name]: errorMsg }));
  };

  const isFormValid = () => {
    const listOfErrors = Object.values(errors).filter((x) => x);
    return listOfErrors.length == 0 && form.address && form.city && form.postCode;
  };

  return (
    <>
      <Text style={addFractionScreenStyle.TitleFont}>
        {t('ownerFractionsScreen.addAFraction.page2.title')}
      </Text>

      <View style={addFractionScreenStyle.FormContainer}>
        <InputBox
          label={t('ownerFractionsScreen.addAFraction.page2.address')}
          onChange={(value) => handleChange('address', value)}
          onError={(msg) => handleError('address', msg)}
          placeholder={t('ownerFractionsScreen.addAFraction.page2.addressPlaceholder')}
          style={addFractionScreenStyle.InputField}
          validations={[isNotEmpty(t('ownerFractionsScreen.validation.fieldNotEmpty'))]}
          value={form.address}
        />

        <InputBox
          label={t('ownerFractionsScreen.addAFraction.page2.city')}
          onChange={(value) => handleChange('city', value)}
          onError={(msg) => handleError('city', msg)}
          placeholder={t('ownerFractionsScreen.addAFraction.page2.cityPlaceholder')}
          style={addFractionScreenStyle.InputField}
          validations={[isNotEmpty(t('ownerFractionsScreen.validation.fieldNotEmpty'))]}
          value={form.city}
        />

        <InputBox
          label={t('ownerFractionsScreen.addAFraction.page2.postalCode')}
          maxLength={5}
          numbersOnly
          onChange={(value) => handleChange('postCode', value)}
          onError={(msg) => handleError('postCode', msg)}
          placeholder={t('ownerFractionsScreen.addAFraction.page2.postalCodePlaceholder')}
          style={addFractionScreenStyle.InputField}
          validations={[
            isNotEmpty(t('ownerFractionsScreen.validation.fieldNotEmpty')),
            isNumber(t('ownerFractionsScreen.validation.invalidNumber')),
            minLength(5, t('ownerFractionsScreen.validation.minimumLength')),
          ]}
          value={form.postCode}
        />

        <TextButton
          bgColor="white"
          border
          disabled={!isFormValid()}
          icon="NEXT"
          iconSide="RIGHT"
          onClick={props.onContinue}
          style={addFractionScreenStyle.ContinueButton}
          textColor={isFormValid() ? 'black' : 'grey4'}
          title={t('ownerFractionsScreen.addAFraction.continueBtn')}
          type="ROUND"
        />
      </View>
    </>
  );
}
