import React, { useEffect, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetAllDeliveriesQuery, useGetCurrentDeliveriesQuery } from 'redux/apis/fraction.api';
import {
  addFractionsToPagedList,
  clearFractionsList,
  getFractionsList,
  nextFractionsPage,
  selectFraction,
  setCurrentDelivery,
} from 'redux/slices/fraction.slice';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { flatFractionsWithDelivery } from '../../../../common/utils/utils';
import { OwnerStackProps } from '../../../../navigation/OwnerTabNavigator';
import { OwnerFractionsScreen } from './OwnerFractionsScreen';

export function OwnerFractions({ navigation }: OwnerStackProps<'OwnerFractions'>) {
  const dispatch = useAppDispatch();
  const flatListRef = useRef<FlatList>();

  const currentDeliveries = useGetCurrentDeliveriesQuery(undefined);

  useEffect(() => {
    if (
      currentDeliveries.isSuccess &&
      !currentDeliveries.isFetching &&
      currentDeliveries.data.totalElements > 0
    ) {
      const fractionWithDelivery = flatFractionsWithDelivery(currentDeliveries.data.data)[0]; //Consider only one possible current delivery
      dispatch(setCurrentDelivery(fractionWithDelivery));
      dispatch(selectFraction(fractionWithDelivery));
      navigation.navigate('OwnerCurrentDelivery');
    }
  }, [currentDeliveries.isSuccess, currentDeliveries.isFetching]);

  const [refreshing, setRefreshing] = useState(false);
  const fractions = useAppSelector(getFractionsList);
  const page = useAppSelector((state) => state.fractions.page);

  const pageSize = 10;
  const deliveriesQuery = useGetAllDeliveriesQuery({
    pageNumber: page,
    pageSize,
    sortDir: 'DESC',
  });

  useEffect(() => {
    if (deliveriesQuery.isSuccess && !deliveriesQuery.isFetching) {
      setRefreshing(false);
      const fractionsWithDelivery = flatFractionsWithDelivery(deliveriesQuery.data.data);
      dispatch(addFractionsToPagedList({ page, fractions: fractionsWithDelivery }));
    }
    if (page == 1)
      //Hook that runs after adding a fraction
      flatListRef.current.scrollToOffset({ animated: false, offset: 0 });
  }, [deliveriesQuery]);

  const onRefresh = () => {
    setRefreshing(true);
    dispatch(clearFractionsList());
    deliveriesQuery.refetch();
  };

  const onSelectFraction = (fraction: FractionWithDelivery) => {
    dispatch(selectFraction(fraction));
    navigation.navigate(fraction.isSelfDelivery ? 'OwnerCurrentDelivery' : 'OwnerFractionDetails');
  };

  return (
    <OwnerFractionsScreen
      flatListRef={flatListRef}
      fractions={fractions}
      onAddFraction={() => navigation.navigate('AddFraction')}
      onEndReached={() => page < deliveriesQuery.data.totalPages && dispatch(nextFractionsPage())}
      onRefresh={onRefresh}
      onSelect={onSelectFraction}
      refreshing={refreshing}
    />
  );
}
