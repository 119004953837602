import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { IconButton } from './IconButton';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';

const props = {
  type: 'NEXT' as const,
  color: 'primaryNavy' as const,
  size: 'LARGE' as const,
};

storiesOf('components/IconButton', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('next', () => <IconButton {...object('props', props)} />)
  .add('previous', () => <IconButton {...object('props', { ...props, type: 'PREVIOUS' })} />)
  .add('bell', () => <IconButton {...object('props', { ...props, type: 'BELL' })} />)
  .add('bell w/ notification', () => (
    <IconButton {...object('props', { ...props, type: 'BELL', notification: true })} />
  ))
  .add('exit', () => <IconButton {...object('props', { ...props, type: 'EXIT' })} />)
  .add('number small', () => (
    <IconButton {...object('props', { ...props, type: 'TEXT', text: '1', size: 'SMALL' })} />
  ))
  .add('number disabled', () => (
    <IconButton
      {...object('props', {
        ...props,
        type: 'TEXT',
        text: '2',
        size: 'SMALL',
        color: 'grey7',
        reverseColor: true,
        disabled: true,
      })}
    />
  ));
