import {
  CompositeScreenProps,
  LinkingOptions,
  NavigationContainer,
  NavigatorScreenParams,
} from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import StorybookUI from '../../.storybook/StorybookUI';
import { isNotEmpty } from '../common/utils/fieldValidator';
import { useAppSelector } from '../redux/hooks';
import { RoleNameEnum } from '../redux/models/role.model';
import { AddFraction } from '../ui/screens/OwnerScreens/AddFraction/AddFraction';
import { IntroScreen } from '../ui/screens/LoginScreen/IntroScreen/IntroScreen';
import { LoginScreen } from '../ui/screens/LoginScreen/LoginScreen';
import { DriverTabNavigator, DriverTabScreens } from './DriverTabNavigator';
import { OwnerTabNavigator, OwnerTabScreens } from './OwnerTabNavigator';
import { StackScreenProps } from '@react-navigation/stack';

export type RootStackScreens = {
  OwnerTabNavigator: NavigatorScreenParams<OwnerTabScreens>;
  DriverTabNavigator: NavigatorScreenParams<DriverTabScreens>;
  IntroScreen: undefined;
  LoginScreen: undefined;
  AddFraction: undefined;
  StorybookUI: undefined;
};

export type RootStackProps<T extends keyof RootStackScreens> = CompositeScreenProps<
  NativeStackScreenProps<RootStackScreens, T>,
  CompositeScreenProps<StackScreenProps<DriverTabScreens>, StackScreenProps<OwnerTabScreens>>
>;

const Stack = createNativeStackNavigator<RootStackScreens>();

const linking: LinkingOptions<RootStackScreens> = {
  prefixes: [],
  config: {
    screens: {
      StorybookUI: 'sb',
    },
  },
};

export function Navigator() {
  //TODO: removed code to log user out when use changes the app

  // const dispatch = useAppDispatch();

  // const rememberMe = useAppSelector((state) => state.auth.rememberMe);
  // const [state, setState] = useState(AppState.currentState);

  // useEffect(() => {
  //   AppState.addEventListener('change', (nextState) => setState(nextState));
  // }, []);

  // useEffect(() => {
  //   if (state !== 'active' && state !== 'inactive' && !rememberMe) {
  //     dispatch(setAuth({}));
  //     dispatch(resetAllNonPersistentStates());
  //   }
  // }, [state]);

  const auth = useAppSelector((state) => state.auth);
  const now = Math.floor(new Date().getTime() / 1000);

  const isLoggedIn: boolean =
    auth && isNotEmpty().condition(auth.accessToken) && (auth.expiresAt || 0) > now;

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {!isLoggedIn ? (
          <Stack.Group>
            <Stack.Screen component={IntroScreen} name="IntroScreen" />

            <Stack.Screen component={LoginScreen} name="LoginScreen" />
          </Stack.Group>
        ) : null}

        {isLoggedIn && auth.role == RoleNameEnum.OWNER ? (
          <Stack.Group>
            <Stack.Screen component={OwnerTabNavigator} name="OwnerTabNavigator" />

            <Stack.Screen component={AddFraction} name="AddFraction" />
          </Stack.Group>
        ) : null}

        {isLoggedIn && auth.role == RoleNameEnum.DRIVER ? (
          <Stack.Group>
            <Stack.Screen component={DriverTabNavigator} name="DriverTabNavigator" />
          </Stack.Group>
        ) : null}

        {__DEV__ ? <Stack.Screen component={StorybookUI} name="StorybookUI" /> : null}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
