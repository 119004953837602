import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  width: number;
  color: string;
  strokeWidth?: number;
  dotted: boolean;
  style?: any;
};

export function HorizontalLine(props: Props) {
  return (
    <Svg color={props.color} height={6} style={props.style} width={props.width}>
      <Path
        d={`M 0,3 H ${props.width}`}
        stroke={props.color}
        strokeDasharray={props.dotted ? '5,3' : '0'}
        strokeWidth={props.strokeWidth || 1}
      />
    </Svg>
  );
}
