import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Dropdown, InputBox, TextButton } from '../../../components';
import { addFractionScreenStyle } from './AddFraction.style';
import { isNotEmpty, isNumber, min } from '../../../../common/utils/fieldValidator';
import { useGetAllFractionTypesQuery } from '../../../../redux/apis/fraction.api';

export type AddFractionForm1 = {
  type: string;
  typeName: string;
  typeUuid: string;
  quantity: string;
  length: string;
  width: string;
  height: string;
};

type Props = {
  form: AddFractionForm1;
  onChange?: (form?: AddFractionForm1) => void;
  onContinue: (form?: AddFractionForm1) => void;
};

export function AddFractionScreen1(props: Props) {
  const { t } = useTranslation();

  const [form, setForm] = useState(props.form);
  const [errors, setErrors] = useState({});

  const fractionTypesQuery = useGetAllFractionTypesQuery(undefined);

  useEffect(() => {
    props.onChange ? props.onChange(form) : null;
  }, [form]);

  const handleChange = (name: keyof AddFractionForm1, value: string) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const handleError = (name: keyof AddFractionForm1, errorMsg: string) => {
    setErrors((errors) => ({ ...errors, [name]: errorMsg }));
  };

  const isFormValid = () => {
    const listOfErrors = Object.values(errors).filter((x) => x);
    return (
      listOfErrors.length == 0 &&
      form.type &&
      form.type != '0' &&
      form.quantity &&
      form.length &&
      form.width &&
      form.height
    );
  };

  const sortAlphabetically = (array: { label: string; value: string }[]) => {
    return array.sort(function (a, b) {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  };

  return (
    <>
      <Text style={addFractionScreenStyle.TitleFont}>
        {t('ownerFractionsScreen.addAFraction.page1.title')}
      </Text>

      <View style={addFractionScreenStyle.FormContainer}>
        {fractionTypesQuery.isSuccess ? (
          <Dropdown
            data={sortAlphabetically(
              fractionTypesQuery.data.map((type) => ({
                label: t(`fractionTypes.${type.uuid}`),
                value: type.id.toString(),
              })),
            )}
            label={t('ownerFractionsScreen.addAFraction.page1.typeOfMaterial')}
            onChange={(value) => {
              handleChange('type', value);
              const type = fractionTypesQuery.data.find((t) => t.id.toString() == value);
              handleChange('typeUuid', `${type?.uuid}`);
              handleChange('typeName', t(`fractionTypes.${type?.uuid}`));
            }}
            onError={(msg) => handleError('type', msg)}
            placeholder={t('ownerFractionsScreen.addAFraction.page1.placeholderType')}
            validation={{
              condition: (value) => value == '0',
              msg: t('ownerFractionsScreen.validation.fieldNotEmpty'),
            }}
            value={form.type}
          />
        ) : null}

        <InputBox
          label={t('ownerFractionsScreen.addAFraction.page1.quantity')}
          maxLength={5}
          numbersOnly
          onChange={(value) => handleChange('quantity', value)}
          onError={(msg) => handleError('quantity', msg)}
          placeholder={t('ownerFractionsScreen.addAFraction.page1.placeholderNumber')}
          style={addFractionScreenStyle.InputField}
          validations={[
            min(0, t('ownerFractionsScreen.validation.minimumValue')),
            isNumber(t('ownerFractionsScreen.validation.invalidNumber')),
            isNotEmpty(t('ownerFractionsScreen.validation.fieldNotEmpty')),
          ]}
          value={form.quantity}
        />

        <View style={addFractionScreenStyle.DimentionUnitContainer}>
          <InputBox
            label={t('ownerFractionsScreen.addAFraction.page1.maxLength')}
            maxLength={5}
            numbersOnly
            onChange={(value) => handleChange('length', value)}
            onError={(msg) => handleError('length', msg)}
            placeholder={t('ownerFractionsScreen.addAFraction.page1.placeholderNumber')}
            style={addFractionScreenStyle.InputField}
            validations={[
              min(0, t('ownerFractionsScreen.validation.minimumValue')),
              isNumber(t('ownerFractionsScreen.validation.invalidNumber')),
              isNotEmpty(t('ownerFractionsScreen.validation.fieldNotEmpty')),
            ]}
            value={form.length}
          />
        </View>

        <View style={addFractionScreenStyle.DimentionUnitContainer}>
          <InputBox
            label={t('ownerFractionsScreen.addAFraction.page1.maxHeight')}
            maxLength={5}
            numbersOnly
            onChange={(value) => handleChange('height', value)}
            onError={(msg) => handleError('height', msg)}
            placeholder={t('ownerFractionsScreen.addAFraction.page1.placeholderNumber')}
            style={addFractionScreenStyle.InputField}
            validations={[
              min(0, t('ownerFractionsScreen.validation.minimumValue')),
              isNumber(t('ownerFractionsScreen.validation.invalidNumber')),
              isNotEmpty(t('ownerFractionsScreen.validation.fieldNotEmpty')),
            ]}
            value={form.height}
          />
        </View>

        <View style={addFractionScreenStyle.DimentionUnitContainer}>
          <InputBox
            label={t('ownerFractionsScreen.addAFraction.page1.maxWidth')}
            maxLength={5}
            numbersOnly
            onChange={(value) => handleChange('width', value)}
            onError={(msg) => handleError('width', msg)}
            placeholder={t('ownerFractionsScreen.addAFraction.page1.placeholderNumber')}
            style={addFractionScreenStyle.InputField}
            validations={[
              min(0, t('ownerFractionsScreen.validation.minimumValue')),
              isNumber(t('ownerFractionsScreen.validation.invalidNumber')),
              isNotEmpty(t('ownerFractionsScreen.validation.fieldNotEmpty')),
            ]}
            value={form.width}
          />
        </View>
      </View>

      <TextButton
        bgColor="white"
        border
        disabled={!isFormValid()}
        icon="NEXT"
        iconSide="RIGHT"
        onClick={props.onContinue}
        style={addFractionScreenStyle.ContinueButton}
        textColor={isFormValid() ? 'black' : 'grey4'}
        title={t('ownerFractionsScreen.addAFraction.continueBtn')}
        type="ROUND"
      />
    </>
  );
}
