import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palette } from '../../../common/styles/global.style';
import { timestampStyle } from './Timestamp.style';
import { TickIcon } from './../Icons/TickIcon';
import { VerticalLine } from '../Icons/VerticalLine';

type Props = {
  name?: string;
  description: string;
  date: string;
  time: string;
  initiated: boolean;
  dotted?: boolean;
  isLast?: boolean;
};

export function Timestamp(props: Props) {
  return (
    <View style={timestampStyle.Container} testID={`Timestamp_${props.name}`}>
      <View
        style={StyleSheet.flatten([
          timestampStyle.TickIconContainer,
          {
            backgroundColor: props.initiated ? palette.accentNavy3 : palette.white,
          },
        ])}
      >
        {props.initiated ? <TickIcon color={palette.white} height={10} /> : null}
      </View>

      {!props.isLast ? (
        <VerticalLine
          color={palette.accentNavy3}
          dotted={props.dotted}
          height={120}
          strokeWidth={1}
          style={timestampStyle.VerticalLine}
        />
      ) : (
        <View style={timestampStyle.Hider} />
      )}

      <Text
        style={StyleSheet.flatten([
          timestampStyle.DescriptioFont,
          {
            color: props.initiated ? palette.grey7 : palette.grey5,
          },
        ])}
      >
        {props.description}
      </Text>

      <View style={timestampStyle.TimeContainer}>
        {props.initiated ? (
          <>
            <Text style={timestampStyle.DateFont}>{props.date}</Text>

            <Text style={timestampStyle.TimeFont}>{props.time}</Text>
          </>
        ) : null}
      </View>
    </View>
  );
}
