import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';
import { ProfilePicture } from './ProfilePicture';

const props = {
  imageUri:
    'https://play-lh.googleusercontent.com/MmLHNN4_lwIN7kMG7XWnOxSYbEju-FBMEn8oDj4Xt8t9EnnH6S6GQeMHJDWpGfeDOSpM',
};

storiesOf('components/ProfilePicture', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('profile', () => <ProfilePicture {...props} />)
  .add('notification', () => <ProfilePicture {...{ ...props, notification: true }} />);
