import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';
import { RewardCard } from './RewardCard';
import { RewardCurrencyEnum } from '../../../redux/models/reward.model';

const props = {
  reward: { currency: RewardCurrencyEnum.EUR, amount: '200.00' },
  title: 'Reward Card',
  description: '5 units, 120 x 400 cm',
  address: 'Kieled Straße, 2, Hamburg 21073',
};

storiesOf('groups/RewardCard', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('card', () => <RewardCard {...props} />)
  .add('higher value', () => (
    <RewardCard
      {...{ ...props, reward: { currency: RewardCurrencyEnum.EUR, amount: '12500.00' } }}
    />
  ));
