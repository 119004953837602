import React, { useState, useEffect } from 'react';
import { Text, Image, View, ScrollView } from 'react-native';
import { CheckBox, InputBox, KeyboardAvoider, TextButton } from '../../components';
import { useTranslation } from 'react-i18next';
import { loginScreenStyle } from './LoginScreen.style';
import { isNotEmpty } from '../../../common/utils/fieldValidator';
import { globalStyle } from '../../../common/styles/global.style';
import { useLoginMutation } from '../../../redux/apis/auth.api';
import { useAppDispatch } from '../../../redux/hooks';
import { setAuth } from '../../../redux/slices/auth.slice';
import { RoleNameEnum } from '../../../redux/models/role.model';

type Form = {
  username: string;
  password: string;
};

export function LoginScreen() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [form, setForm] = useState({} as Form);
  const [rememberMe, setRememberMe] = useState(false);
  const [serverError, setServerError] = useState('');
  const [login, loginMutation] = useLoginMutation();

  useEffect(() => {
    if (loginMutation.isSuccess && loginMutation.data.role !== RoleNameEnum.ADMIN) {
      dispatch(setAuth({ ...loginMutation.data, rememberMe }));
    }
    if (loginMutation.data?.role === RoleNameEnum.ADMIN) {
      setServerError(t('errorMsgs.Unauthorized'));
    }
    if (loginMutation.isError) {
      const errorStatus = (loginMutation.error as any)?.status ?? 500;
      setServerError(
        errorStatus > 499 ? t('errorMsgs.InternalServerError') : t('errorMsgs.LoginFailed'),
      );
    }
  }, [loginMutation]);

  const handleChange = (name: keyof Form, value?: string) => {
    setServerError('');
    setForm({ ...form, [name]: value });
  };

  return (
    <KeyboardAvoider>
      <Image
        source={require('../../../assets/background/RoundCubeBlue.png')}
        style={loginScreenStyle.BlueCubeBg}
      />

      <Image
        source={require('../../../assets/background/RoundCubeWhite.png')}
        style={loginScreenStyle.WhiteCubeBg}
      />

      <ScrollView contentContainerStyle={loginScreenStyle.AlignContainer}>
        <Text style={loginScreenStyle.TitleFont}>{t('loginScreen.title')}</Text>

        <Text style={loginScreenStyle.SubtitleFont}>{t('loginScreen.subtitle')}</Text>

        <View style={loginScreenStyle.InputContainer}>
          <InputBox
            label={t('loginScreen.username')}
            onChange={(value) => handleChange('username', value)}
            placeholder={t('loginScreen.usernamePlaceholder')}
            style={loginScreenStyle.InputBox}
            validations={[isNotEmpty(t('loginScreen.validation.emptyUsername'))]}
            value={form.username}
          />

          <View style={loginScreenStyle.HorizontalSpacer} />

          <InputBox
            isPassword
            label={t('loginScreen.password')}
            onChange={(value) => handleChange('password', value)}
            placeholder={t('loginScreen.passwordPlaceholder')}
            style={loginScreenStyle.InputBox}
            validations={[isNotEmpty(t('loginScreen.validation.emptyPassword'))]}
            value={form.password}
          />

          {serverError ? <Text style={globalStyle.ErrorFont}>{serverError}</Text> : null}

          <View style={loginScreenStyle.LinksContainer}>
            <CheckBox
              label={t('loginScreen.rememberMe')}
              onChange={(value) => setRememberMe(value)}
            />

            {/* <Text style={loginScreenStyle.ForgotPWFont}>
                          {t('loginScreen.forgotPassword')}
                      </Text> */}
          </View>

          <TextButton
            bgColor="primaryNavy"
            onClick={() => {
              form.password && form.username ? login(form) : null;
            }}
            textColor="white"
            title="Login"
            type="ROUND"
          />

          <View style={loginScreenStyle.HorizontalSpacer} />
        </View>
      </ScrollView>
    </KeyboardAvoider>
  );
}
