import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const pickupDateCardStyle = StyleSheet.create({
  DateFont: {
    color: palette.grey8,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 24,
    letterSpacing: 0.04,
    lineHeight: 24,
    marginLeft: 8,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  DateTimeContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginLeft: 8,
    marginTop: 16,
    width: '100%',
    zindex: 1,
  },
  HeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    zindex: 1,
  },
  MainContainer: {
    backgroundColor: palette.grey1,
    justifyContent: 'flex-start',
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    width: '100%',
    zindex: -1,
  },
  TimeFont: {
    color: palette.grey8,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 24,
    letterSpacing: 0.04,
    lineHeight: 24,
    marginLeft: 8,
    marginRight: 'auto',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  TitleHeaderFont: {
    color: palette.grey6,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 24,
    letterSpacing: 0.04,
    lineHeight: 24,
    marginLeft: 8,
    marginRight: 'auto',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
});
