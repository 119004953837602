import { StyleSheet } from 'react-native';

export const pageIndexStyle = StyleSheet.create({
  Container: {
    flexDirection: 'row',
  },
  Line: {
    marginTop: 13,
  },
});
