import * as React from 'react';
import { Text, View, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { radioButtonStyle } from './RadioButton.style';
import { palette } from '../../../common/styles/global.style';

type Props = {
  label?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value?: boolean) => void;
};

export function RadioButton(props: Props) {
  const [value, setValue] = React.useState(props.value || false);

  React.useEffect(() => {
    props.onChange ? props.onChange(value) : null;
  }, [value]);
  React.useEffect(() => {
    setValue(props.value);
  }, [props]);

  const select = () => props.disabled ?? setValue(true);

  let selectedStyle: any = radioButtonStyle.Selected;
  let unselectedStyle: any = radioButtonStyle.Unselected;
  let fontStyle: any = radioButtonStyle.Font;
  if (props.disabled) {
    selectedStyle = StyleSheet.compose(selectedStyle, {
      backgroundColor: palette.grey3,
      borderColor: palette.grey3,
    });
    unselectedStyle = StyleSheet.compose(unselectedStyle, {
      borderColor: palette.grey3,
    });
    fontStyle = StyleSheet.compose(fontStyle, {
      color: palette.grey3,
    });
  }

  return (
    <TouchableWithoutFeedback
      disabled={props.disabled}
      onPress={select}
      testID={`RadioButton_${props.label.replace(' ', '')}`}
    >
      <View style={radioButtonStyle.Box}>
        <View style={value ? selectedStyle : unselectedStyle}>
          {value ? <View style={radioButtonStyle.InnerBall} /> : null}
        </View>

        {props.label ? <Text style={fontStyle}>{props.label}</Text> : null}
      </View>
    </TouchableWithoutFeedback>
  );
}
