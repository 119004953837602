import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  rotation?: number;
  xmlns?: string;
  style?: any;
};

export function DeleteIcon(props: Props) {
  return (
    <Svg
      fill="none"
      height={props.height}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <Path 
            d="M5.11617 1.38792C5.2915 1.03884 5.65189 0.818359 6.04474 0.818359H9.95384C10.3467 0.818359 10.7071 1.03884 10.8824 1.38792L11.1162 1.84866H14.2331C14.8077 1.84866 15.272 2.31004 15.272 2.87897C15.272 3.44789 14.8077 3.90927 14.2331 3.90927H1.76552C1.19182 3.90927 0.726562 3.44789 0.726562 2.87897C0.726562 2.31004 1.19182 1.84866 1.76552 1.84866H4.88241L5.11617 1.38792ZM13.5447 15.8254C13.4928 16.6689 12.8142 17.3032 11.9895 17.3032H4.00903C3.18598 17.3032 2.50481 16.6689 2.45351 15.8254L1.7363 4.93957H14.2331L13.5447 15.8254Z" 
            fill={props.color}
        />
    </Svg>
  );
}