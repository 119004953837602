import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const fractionCardStyle = StyleSheet.create({
  Background: {
    height: 220,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 390,
    zIndex: -1,
  },
  Badge: {
    backgroundColor: palette.primaryNavy,
    borderRadius: 100,
    flexDirection: 'row',
    height: 28,
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    paddingVertical: 6,
    zindex: 2,
  },
  BadgeFont: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: 16,
    textAlign: 'right',
  },
  CardAsButton: {
    backgroundColor: palette.transparent,
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 5,
  },
  DescriptionContainer: {
    flexDirection: 'row',
    height: 28,
    justifyContent: 'flex-start',
    width: '100%',
    zindex: 1,
  },
  DescriptionFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginLeft: 8,
    marginTop: 6,
    textAlign: 'left',
  },
  FractionIdContainer: {
    flexDirection: 'row',
    height: 24,
    justifyContent: 'flex-start',
    paddingLeft: 8,
    width: '100%',
    zindex: 1,
  },
  FractionIdFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '700',
    height: 24,
    letterSpacing: 0.02,
    lineHeight: 24,
    marginBottom: 6,
    marginLeft: 0,
    textAlign: 'left',
  },
  FractionIdFont2: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 24,
    letterSpacing: 0.02,
    lineHeight: 24,
    marginBottom: 6,
    marginLeft: 6,
    textAlign: 'left',
  },
  HeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    zindex: 1,
  },
  HistoryFont: {
    color: palette.grey6,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginLeft: 8,
    textAlign: 'left',
  },
  Icon: {
    left: 8,
    marginRight: 8,
    top: 10,
  },
  LocationIcon: {
    marginLeft: 2,
  },
  MainContainer: {
    backgroundColor: palette.grey1,
    justifyContent: 'flex-start',
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    width: '100%',
    zindex: -1,
  },
  OptionButton: {
    borderColor: palette.grey7,
    borderRadius: 8,
    borderWidth: 1,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: 8,
    marginVertical: 16,
    paddingHorizontal: 12,
    paddingVertical: 12,
    zIndex: 7,
  },
  OptionButtonFont: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 16,
    textTransform: 'uppercase',
    zIndex: 9,
  },
  OptionsContainer: {
    flexDirection: 'row',
    height: 70,
    justifyContent: 'flex-start',
    width: '100%',
    zindex: 5,
  },
  OwnerFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginLeft: 2,
    marginTop: 6,
    textAlign: 'left',
    zIndex: 2,
  },
  PublishedByFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicHeavy,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 69,
    letterSpacing: 0.01,
    lineHeight: 27,
    marginLeft: 8,
    marginTop: 12,
    textAlign: 'left',
    textAlignVertical: 'top',
    zIndex: 2,
  },
  StatusTagContainer: {
    flexDirection: 'row',
    height: 24,
    justifyContent: 'flex-start',
    paddingLeft: 8,
    width: '100%',
    zindex: 1,
  },
  TitleFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicHeavy,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 28,
    letterSpacing: 0.01,
    lineHeight: 25,
    marginLeft: 8,
    marginTop: 12,
    textAlign: 'left',
    textAlignVertical: 'top',
    zIndex: 2,
  },
  TitleHeaderFont: {
    color: palette.grey6,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 24,
    letterSpacing: 0.04,
    lineHeight: 24,
    marginLeft: 8,
    marginRight: 'auto',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  Trophee: {
    marginRight: 5,
  },
});
