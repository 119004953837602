/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { palette } from '../../../common/styles/global.style';
import { phaseDisplayStyle } from './PhaseDisplay.style';

type Props = {
  currentPhase: number;
  phaseDescriptions: string[];
  style?: any;
};

const r = 36; // radius
const w = 4; // width of the line
const s = 8; // grad - space between arcs

export function PhaseDisplay(props: Props) {
  const { t } = useTranslation();

  const angle = Math.floor(360 / props.phaseDescriptions.length);
  const y = r * Math.cos(((angle - 2 * s) * Math.PI) / 180); // end of the arc coordinates
  const x = r * Math.sin(((angle - 2 * s) * Math.PI) / 180);

  const circleArc = (i: number) => {
    return (
      <Svg
        fill={palette.transparent}
        height={2 * (r + w)}
        key={i}
        stroke={i < props.currentPhase ? palette.successGreen1 : palette.white}
        strokeLinecap="round"
        strokeWidth={2 * w}
        style={{
          position: 'absolute',
          transform: [{ rotateZ: `${i * angle + s}deg` }],
        }}
        width={2 * (r + w)}
      >
        <Path d={`m${w + r} ${w}a${r} ${r} 0 0 1 ${x} ${r - y}`} />
      </Svg>
    );
  };

  const circle = () => {
    const arcs = [];
    for (let i = 0; i < props.phaseDescriptions.length; i++) {
      arcs.push(circleArc(i));
    }
    return arcs;
  };

  return (
    <View style={props.style}>
      <View style={phaseDisplayStyle.MainContainer}>
        <View style={phaseDisplayStyle.HalfCircle}>
          {circle()}

          <Text style={phaseDisplayStyle.Percentage}>
            {((props.currentPhase / props.phaseDescriptions.length) * 100).toFixed(0) + '%'}
          </Text>
        </View>

        <View style={phaseDisplayStyle.TextContainer}>
          <Text style={phaseDisplayStyle.PhaseFont}>
            {t('ui.components.phaseDisplay.phaseOf')
              .replace('{{current}}', props.currentPhase.toString())
              .replace('{{total}}', props.phaseDescriptions.length.toString())}
          </Text>

          <Text style={phaseDisplayStyle.DescriptionFont}>
            {props.phaseDescriptions[props.currentPhase - 1]}
          </Text>
        </View>
      </View>
    </View>
  );
}
