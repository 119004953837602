import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: any;
};

export function TickIcon(props: Props) {
  return (
    <Svg
      fill="none"
      height={props.height}
      width={(17 / 16) * props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M1 8.533 6.333 15 16 1"
        scale={props.height / 16}
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </Svg>
  );
}
