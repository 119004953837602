import moment from 'moment';
import { t } from 'i18next';
import {
  DeliveryRequestResponseDto,
  FractionWithDelivery,
} from '../../redux/models/fraction.model';
import { Phase, PhasesEnum } from '../../redux/models/phase.model';
import { AddressDto } from './../../redux/models/address.model';
import { RewardDto } from '../../redux/models/reward.model';
import { RoleNameEnum } from '../../redux/models/role.model';

export function getTimeSpan(date: Date): string {
  const mins = Math.floor(moment().diff(date, 'minutes'));
  if (mins < 0) return `${0} ${t('time.min')}`;
  if (mins < 60) return `${mins} ${t('time.min')}`;
  const hours = Math.floor(moment().diff(date, 'hours'));
  if (hours <= 1) return `${hours} ${t('time.hour')}`;
  if (hours < 24) return `${hours} ${t('time.hours')}`;
  const days = Math.floor(moment().diff(date, 'days'));
  if (days <= 1) return `${days} ${t('time.day')}`;
  if (days < 24) return `${days} ${t('time.days')}`;
  const weeks = Math.floor(moment().diff(date, 'weeks'));
  if (weeks <= 1) return `${weeks} ${t('time.week')}`;
  if (weeks < 24) return `${weeks} ${t('time.weeks')}`;
}

export function flatFractionsWithDelivery(
  deliveryRequests: DeliveryRequestResponseDto[],
): FractionWithDelivery[] {
  const nextPageFractions: FractionWithDelivery[] = [];
  deliveryRequests.forEach((dr) => {
    dr.fractions.forEach((f) => {
      const drCopy = { ...dr };
      delete drCopy.id;
      delete drCopy.fractions;
      nextPageFractions.push({ ...f, ...drCopy, fractionId: f.id, deliveryId: dr.id });
    });
  });
  return nextPageFractions;
}

export function flatFractionWithDelivery(
  deliveryRequest: DeliveryRequestResponseDto,
  fractionId: number,
): FractionWithDelivery {
  const fraction = deliveryRequest.fractions.find((f) => f.id == fractionId);
  const drCopy = { ...deliveryRequest };
  delete drCopy.id;
  delete drCopy.fractions;
  return { ...fraction, ...drCopy, fractionId: fraction.id, deliveryId: deliveryRequest.id };
}

export function removeCents(reward: RewardDto): RewardDto {
  return {
    amount: `${parseFloat(reward.amount).toFixed(0)}`,
    currency: reward.currency,
  };
}

export const getPhases = (phases: Phase[], datePaymentReceived?: Date): Phase[] =>
  [
    ...phases,
    {
      id: 999,
      order: 999,
      dateFinished: datePaymentReceived,
      dateStarted: phases.find((p) => p.order == Math.max(...phases.map((p) => p.order)))
        .dateFinished,
      name: PhasesEnum.COMPLETE,
    },
  ].sort((a, b) => a.order - b.order);

export function getAddressString(address: AddressDto): string {
  return `${address.streetName}, ${address.postCode} ${address.city}`;
}

export function getRewardByRole(fraction: FractionWithDelivery, role: RoleNameEnum): RewardDto {
  switch (role) {
    case RoleNameEnum.OWNER:
      if (fraction.isSelfDelivery)
        return {
          amount: `${(
            parseFloat(fraction.deliveryReward.amount) + parseFloat(fraction.materialReward.amount)
          ).toFixed(0)}`,
          currency: fraction.materialReward.currency,
        };
      return removeCents(fraction.materialReward);
    case RoleNameEnum.DRIVER:
      return removeCents(fraction.deliveryReward);
    default:
      return null;
  }
}
