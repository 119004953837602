import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: any;
};

export function FractionIcon(props: Props) {
  return (
    <Svg
      fill="none"
      height={props.height}
      width={props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M.889 8.889h5.333c.49 0 .89-.4.89-.889V.889c0-.489-.4-.889-.89-.889H.89C.4 0 0 .4 0 .889V8c0 .489.4.889.889.889Zm0 7.111h5.333c.49 0 .89-.4.89-.889v-3.555c0-.49-.4-.89-.89-.89H.89c-.489 0-.889.4-.889.89v3.555C0 15.6.4 16 .889 16Zm8.889 0h5.333c.489 0 .889-.4.889-.889V8c0-.489-.4-.889-.889-.889H9.778c-.49 0-.89.4-.89.889v7.111c0 .489.4.889.89.889ZM8.888.889v3.555c0 .49.4.89.89.89h5.333c.489 0 .889-.4.889-.89V.89C16 .4 15.6 0 15.111 0H9.778c-.49 0-.89.4-.89.889Z"
        fill={props.color}
        scale={props.height / 16}
      />
    </Svg>
  );
}
