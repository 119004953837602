import { t } from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, View, Text, ScrollView } from 'react-native';
import { globalStyle } from '../../../../common/styles/global.style';
import { mapAddFrationFormToDto } from '../../../../common/utils/mapper';
import { RootStackProps } from '../../../../navigation/Navigator';
import {
  useCreateFractionAndDeliveryMutation,
  useGetDeliveryRewardMutation,
  useGetMaterialRewardMutation,
  usePublishDeliveryMutation,
} from '../../../../redux/apis/fraction.api';
import { useAppDispatch } from '../../../../redux/hooks';
import { clearFractionsList } from '../../../../redux/slices/fraction.slice';
import { IconButton, KeyboardAvoider } from '../../../components';
import { PageIndex } from '../../../groups';
import { addFractionScreenStyle } from './AddFraction.style';
import { AddFractionScreen1, AddFractionForm1 } from './AddFractionScreen1';
import { AddFractionForm2, AddFractionScreen2 } from './AddFractionScreen2';
import { AddFractionForm3, AddFractionScreen3 } from './AddFractionScreen3';
import { AddFractionScreen4 } from './AddFractionScreen4';
import { RewardDto } from './../../../../redux/models/reward.model';

export type AddFractionForm = AddFractionForm1 & AddFractionForm2 & AddFractionForm3;

export function AddFraction({ navigation }: RootStackProps<'AddFraction'>) {
  const dispatch = useAppDispatch();
  const scrollRef = useRef<ScrollView>();

  const [form1, setForm1] = useState({} as AddFractionForm1);
  const [form2, setForm2] = useState({} as AddFractionForm2);
  const [form3, setForm3] = useState({ isSelfDelivery: true } as AddFractionForm3);

  const [page, setPage] = useState(1);

  useEffect(() => {
    scrollRef.current.scrollTo({ x: 0 });
  }, [page]);

  const [createDelivery, createDeliveryMutation] = useCreateFractionAndDeliveryMutation();
  const [publishDelivery, publishDeliveryMutation] = usePublishDeliveryMutation();

  useEffect(() => {
    if (createDeliveryMutation.isSuccess) publishDelivery(createDeliveryMutation.data.id);
  }, [createDeliveryMutation]);

  useEffect(() => {
    if (publishDeliveryMutation.isSuccess) {
      dispatch(clearFractionsList());
      navigation.navigate('OwnerFractions');
    }
  }, [publishDeliveryMutation.isSuccess]);

  const [getDeliveryReward, getDeliveryRewardMutation] = useGetDeliveryRewardMutation();
  const [getMaterialReward, getMaterialRewardMutation] = useGetMaterialRewardMutation();
  const [deliveryReward, setDeliveryReward] = useState({} as RewardDto);
  const [materialReward, setMaterialReward] = useState({} as RewardDto);

  useEffect(() => {
    if (page == 3 && form3.disposalPointId)
      getDeliveryReward(mapAddFrationFormToDto({ ...form1, ...form2, ...form3 }));
  }, [page, form3.disposalPointId]);

  useEffect(() => {
    if (getDeliveryRewardMutation.isSuccess) setDeliveryReward(getDeliveryRewardMutation.data);
  }, [getDeliveryRewardMutation.isSuccess]);

  useEffect(() => {
    if (getMaterialRewardMutation.isSuccess) {
      setMaterialReward(getMaterialRewardMutation.data);
      setPage(4);
    }
  }, [getMaterialRewardMutation.isSuccess]);

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <KeyboardAvoider>
        <View style={globalStyle.HeaderContainer}>
          <IconButton
            color="primaryNavy"
            onClick={() => navigation.goBack()}
            size="LARGE"
            type="PREVIOUS"
          />

          <Text style={globalStyle.TopBarFont}>{t('ownerFractionsScreen.addAFraction.title')}</Text>
        </View>

        <ScrollView ref={scrollRef} style={addFractionScreenStyle.ScrollContainer}>
          <View style={addFractionScreenStyle.CentralContainer}>
            <PageIndex number={4} onChange={setPage} value={page} />

            {page == 1 && (
              <AddFractionScreen1 form={form1} onChange={setForm1} onContinue={() => setPage(2)} />
            )}

            {page == 2 && (
              <AddFractionScreen2 form={form2} onChange={setForm2} onContinue={() => setPage(3)} />
            )}

            {page == 3 && (
              <AddFractionScreen3
                deliveryReward={deliveryReward}
                form={form3}
                onChange={setForm3}
                onContinue={() =>
                  getMaterialReward(mapAddFrationFormToDto({ ...form1, ...form2, ...form3 }))
                }
              />
            )}

            {page == 4 && (
              <AddFractionScreen4
                deliveryReward={deliveryReward}
                form={{ ...form1, ...form2, ...form3 }}
                materialReward={materialReward}
                onPublish={() =>
                  createDelivery(mapAddFrationFormToDto({ ...form1, ...form2, ...form3 }))
                }
              />
            )}
          </View>
        </ScrollView>
      </KeyboardAvoider>
    </SafeAreaView>
  );
}
