/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { View, Text, ScrollView, SafeAreaView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ownerCurrentdeliveryStyle } from './OwnerCurrentDelivery.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, TextButton, Timestamp } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { getAddressString, getPhases } from '../../../../common/utils/utils';
import moment from 'moment';
import { OWNER_AS_DRIVER_PHASES_COMPLETE } from '../../../../common/utils/constants';
import { PhasesEnum } from '../../../../redux/models/phase.model';

type Props = {
  fraction: FractionWithDelivery;
  onNext?: () => void;
};

export function OwnerCurrentDeliveryScreen3({ fraction, onNext }: Props) {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" size="LARGE" type="FRACTION" />

        <Text style={globalStyle.TopBarFont}>{t('fractionDetailsScreen.overview')}</Text>
      </View>

      <ScrollView style={globalStyle.MainContainer}>
        <FractionCard
          currentPhase={3}
          description={`${fraction.quantity} ${
            fraction.quantity == 1 ? t('common.fractionUnit') : t('common.fractionUnits')
          }, ${fraction.dimensions.width}cm x ${fraction.dimensions.length}cm x ${
            fraction.dimensions.height
          }cm`}
          dispoId={fraction.isInterZeroDelivery ? fraction.interZeroId.toString() : null}
          fractionId={fraction.uuid.substring(0, 5)}
          pickupAddress={getAddressString(fraction.pickupAddress)}
          reward={{
            amount: `${(
              parseFloat(fraction.deliveryReward.amount) +
              parseFloat(fraction.materialReward.amount)
            ).toFixed(0)}`,
            currency: fraction.materialReward.currency,
          }}
          title={t(`fractionTypes.${fraction.type.uuid}`)}
          titleHeader={t('currentDeliveryScreen.fraction')}
          view="DRIVER"
        />

        <HorizontalSpacer space={8} />

        <FractionCard
          disposalPoints={[fraction.disposalPoint]}
          titleHeader={t('common.disposalSite')}
        />

        <HorizontalSpacer space={8} />

        <View style={ownerCurrentdeliveryStyle.TimestampsContainer}>
          {getPhases(fraction.ownerPhase.phases, fraction.datePaymentReceived).map((phase) => (
            <Timestamp
              date={moment(phase.dateStarted).format(t('time.dateFormat'))}
              description={OWNER_AS_DRIVER_PHASES_COMPLETE[phase.name]}
              dotted={phase.dateFinished ? false : true}
              initiated={phase.dateStarted ? true : false}
              isLast={phase.name == PhasesEnum.COMPLETE}
              key={phase.name}
              name={phase.name}
              time={moment(phase.dateStarted).format(t('time.timeFormat'))}
            />
          ))}
        </View>

        <HorizontalSpacer space={140} />
      </ScrollView>

      <View style={ownerCurrentdeliveryStyle.PickupFractionBtnContainer}>
        <TextButton
          bgColor="grey8"
          icon="TICK"
          onClick={onNext}
          style={ownerCurrentdeliveryStyle.StartDrivingBtn}
          textColor="white"
          title={t('fractionDetailsScreen.finish')}
          type="SQUARE"
        />
      </View>
    </SafeAreaView>
  );
}
