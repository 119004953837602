import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const radioButtonStyle = StyleSheet.create({
  Box: {
    flexDirection: 'row',
  },
  Font: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 16,
    paddingLeft: 8,
  },
  InnerBall: {
    backgroundColor: palette.primaryNavy,
    borderRadius: 5,
    height: 10,
    width: 10,
  },
  Selected: {
    alignItems: 'center',
    backgroundColor: palette.white,
    borderColor: palette.primaryNavy,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 16,
    paddingTop: 2,
    width: 16,
  },
  Unselected: {
    backgroundColor: palette.white,
    borderColor: palette.grey5,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 16,
    width: 16,
  },
});
