import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { StatusTag, StatusTagType } from './StatusTag';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';

storiesOf('components/StatusTag', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('awaiting payment', () => (
    <StatusTag
      {...object('props', {
        status: StatusTagType.OPEN,
      })}
    />
  ))
  .add('payment received', () => (
    <StatusTag
      {...object('props', {
        status: StatusTagType.SUCCESSFUL,
      })}
    />
  ))
  .add('cancelled', () => (
    <StatusTag
      {...object('props', {
        status: StatusTagType.REJECTED,
      })}
    />
  ));
