import React from 'react';
import { palette } from '../../../common/styles/global.style';
import { BellIcon } from './BellIcon';
import { FractionIcon } from './FractionIcon';
import { HistoryIcon } from './HistoryIcon';
import { LeftArrowIcon } from './LeftArrowIcon';
import { PlusIcon } from './PlusIcon';
import { ProfileIcon } from './ProfileIcon';
import { RightArrowIcon } from './RightArrowIcon';
import { TickIcon } from './TickIcon';
import { UploadIcon } from './UploadIcon';
import { DimensionsIcon } from './DimensionsIcon';
import { LocationIcon } from './LocationIcon';
import { RecycleIcon } from './RecycleIcon';
import { GmapsIcon } from './GmapsIcon';
import { TelephoneIcon } from './TelephoneIcon';
import { DeleteIcon } from './DeleteIcon';
import { CalendarIcon } from './CalendarIcon';

type Props = {
  icon: string;
  color: keyof typeof palette;
  style?: any;
};

export function Icon(props: Props) {
  switch (props.icon) {
    case 'PREVIOUS':
      return <LeftArrowIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'NEXT':
      return <RightArrowIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'BELL':
      return <BellIcon color={palette[props.color]} height={20} style={props.style} />;
    case 'EXIT':
      return (
        <PlusIcon color={palette[props.color]} height={16} rotation={45} style={props.style} />
      );
    case 'FRACTION':
      return <FractionIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'HISTORY':
      return <HistoryIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'PROFILE':
      return <ProfileIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'PLUS':
      return <PlusIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'UPLOAD':
      return <UploadIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'TICK':
      return <TickIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'DIMENSION':
      return <DimensionsIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'LOCATION':
      return <LocationIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'RECYCLE':
      return <RecycleIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'GMAPS':
      return <GmapsIcon />;
    case 'TELEPHONE':
      return <TelephoneIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'DELETE':
      return <DeleteIcon color={palette[props.color]} height={16} style={props.style} />;
    case 'CALENDER':
      return <CalendarIcon color={palette[props.color]} height={16} style={props.style} />;
    default:
      return null;
  }
}
