import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { globalStyle, palette } from 'common/styles/global.style';
import { dropdownStyle } from './Dropdown.style';
import RNPickerSelect from 'react-native-picker-select';
import { ValidationObj } from '../../../common/utils/fieldValidator';

type Props = {
  label?: string;
  data: { label: string; value: string }[];
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  validation?: ValidationObj;
  style?: any;
  onChange?: (value?: string) => void;
  onError?: (msg?: string) => void;
};

export function Dropdown(props: Props) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [value, setValue] = useState(props.value || '');

  useEffect(() => {
    props.onChange ? props.onChange(value) : null;
  }, [value]);

  const errorStyle = {
    borderColor: errorMsg ? palette.red2 : palette.grey3,
  };
  const style = StyleSheet.flatten([dropdownStyle.Dropdown, errorStyle]);

  const onValueChange = (value) => {
    setValue(value);
    if (props.validation) {
      const valid = props.validation.condition(value);
      const validationMsg = valid ? props.validation.msg : null;
      props.onError ? props.onError(validationMsg) : null;
      setErrorMsg(validationMsg);
    }
  };

  return (
    <View style={props.style} testID={`Dropdown_${props.label.replace(' ', '')}`}>
      {props.label ? <Text style={globalStyle.FontSmall}>{props.label}</Text> : null}

      <RNPickerSelect
        disabled={props.disabled}
        items={props.data}
        onValueChange={(itemValue) => onValueChange(itemValue)}
        placeholder={{
          label: props.placeholder,
          value: '0',
          color: palette.grey3,
          key: '0',
        }}
        style={{ inputIOS: style, inputAndroid: style, inputWeb: style }}
        value={value}
      />

      {errorMsg ? <Text style={globalStyle.ErrorFont}>{errorMsg}</Text> : null}
    </View>
  );
}
