import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';
import { InputBox } from './InputBox';

const props = {
  label: 'Email',
};

storiesOf('components/InputBox', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('email', () => <InputBox {...object('props', props)} />)
  .add('error if starts with a', () => (
    <InputBox
      {...object('props', {
        ...props,
        validation: { condition: (s: string) => s.startsWith('a'), msg: 'Must not start with a' },
      })}
    />
  ))
  .add('password with placeholder', () => (
    <InputBox
      {...object('props', {
        ...props,
        label: 'Password',
        isPassword: true,
        placeholder: 'Enter password',
      })}
    />
  ))
  .add('with initial value', () => (
    <InputBox
      {...object('props', {
        ...props,
        value: 'initial value',
      })}
    />
  ));
