import { StyleSheet } from 'react-native';
import { palette } from 'common/styles/global.style';
import { Fonts } from '../../../common/styles/fonts';

export const rewardCardStyle = StyleSheet.create({
  BlueBg: {
    backgroundColor: palette.primaryNavy,
    borderRadius: 16,
    height: 104,
    marginLeft: 97,
    position: 'absolute',
    width: 180,
    zIndex: 2,
  },
  CurrencyFont: {
    color: palette.primaryNavy,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 44,
    marginRight: 10,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  DescriptionFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    marginLeft: 48,
    marginTop: 6,
    textAlign: 'left',
  },
  DimentionIcon: {
    left: 24,
    position: 'absolute',
    top: 166,
  },
  LocationIcon: {
    left: 25,
    position: 'absolute',
    top: 196,
  },
  MainBg: {
    backgroundColor: palette.white,
    borderRadius: 16,
    height: 202,
    marginLeft: 0,
    marginTop: 64,
    position: 'absolute',
    width: 358,
    zIndex: 1,
  },
  MainContainer: {
    alignSelf: 'center',
    left: -179,
    position: 'relative',
    top: 0,
  },
  RecyclingFont: {
    color: palette.primaryNavy,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  RewardContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  RewardFont: {
    color: palette.primaryNavy,
    fontFamily: Fonts.FranklinGothicHeavy,
    fontSize: 48,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 44,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  TextContainer: {
    position: 'absolute',
    top: 20,
    width: 358,
    zIndex: 4,
  },
  TitleFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicHeavy,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 57,
    letterSpacing: 0.01,
    lineHeight: 27,
    marginTop: 24,
    textAlign: 'center',
  },
  WhiteBg: {
    backgroundColor: palette.white,
    borderRadius: 16,
    height: 104,
    marginLeft: 89,
    marginTop: 8,
    position: 'absolute',
    width: 180,
    zIndex: 3,
  },
});
