import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { TextButton } from './TextButton';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';

const props = {
  title: 'Login',
  type: 'ROUND' as const,
  bgColor: 'primaryNavy' as const,
  textColor: 'white' as const,
  onClick: () => void 0,
};

storiesOf('components/TextButton', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('login', () => <TextButton {...object('props', props)} />)
  .add('add fraction', () => (
    <TextButton {...object('props', { ...props, title: 'Add fraction', icon: 'PLUS' })} />
  ))
  .add('upload fraction image', () => (
    <TextButton
      {...object('props', {
        ...props,
        title: 'Upload fraction image',
        type: 'SQUARE',
        bgColor: 'grey8',
        icon: 'UPLOAD',
      })}
    />
  ))
  .add('continue', () => (
    <TextButton
      {...object('props', {
        ...props,
        title: 'Continue',
        bgColor: 'white',
        textColor: 'grey7',
        icon: 'NEXT',
        iconSide: 'RIGHT',
        border: true,
      })}
    />
  ))
  .add('continue disabled', () => (
    <TextButton
      {...object('props', {
        ...props,
        title: 'Continue',
        bgColor: 'white',
        textColor: 'grey3',
        icon: 'NEXT',
        iconSide: 'RIGHT',
        disabled: true,
        border: true,
      })}
    />
  ))
  .add('finish', () => (
    <TextButton
      {...object('props', {
        ...props,
        title: 'FINISH AND ARCHIVE',
        icon: 'TICK',
      })}
    />
  ));
