import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';
import { PageIndex } from './PageIndex';

const props = {
  number: 3,
  value: 2,
  navigation: { [1]: () => void 0 },
};

storiesOf('groups/PageIndex', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('1/3', () => <PageIndex {...props} />);
