import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const phaseDisplayStyle = StyleSheet.create({
  DescriptionFont: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 24,
    minHeight: 56,
    textAlign: 'left',
  },
  HalfCircle: {
    left: 24,
    position: 'absolute',
    top: 24,
  },
  MainContainer: {
    backgroundColor: palette.accentNavy3,
    borderRadius: 8,
    height: 128,
    width: '100%',
    zindex: 1,
  },
  Percentage: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 80,
    letterSpacing: 0.02,
    lineHeight: 32,
    marginTop: 23,
    textAlign: 'center',
    width: 80,
  },
  PhaseFont: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: 24,
    maxWidth: 220,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  TextContainer: {
    marginBottom: 8,
    marginLeft: 128,
    marginTop: 'auto',
    paddingRight: 16,
    zindex: 2,
  },
});
