/* eslint-disable react/no-multi-comp */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';
import { OwnerFractions } from '../ui/screens/OwnerScreens/OwnerFractions/OwnerFractions';
import { globalStyle } from '../common/styles/global.style';
import { ProfileScreen } from '../ui/screens/ProfileScreen/ProfileScreen';
import { OwnerFractionDetails } from '../ui/screens/OwnerScreens/OwnerFractionDetails/OwnerFractionDetails';
import { CompositeScreenProps } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { DriverTabScreens } from './DriverTabNavigator';
import { RootStackScreens } from './Navigator';
import { StackScreenProps } from '@react-navigation/stack';
import { useAppSelector } from '../redux/hooks';
import { fractionsNavBtn, historyNavBtn, profileNavBtn } from './navigationButtons';
import { OwnerCurrentDelivery } from '../ui/screens/OwnerScreens/OwnerCurrentDelivery/OwnerCurrentDelivery';
import { History } from '../ui/screens/HistoryScreen/History';
import {HistoryDetails} from 'ui/screens/HistoryScreen/HistoryDetails/HistoryDetails';

export type OwnerTabScreens = {
  OwnerFractions: undefined;
  OwnerFractionDetails: undefined;
  OwnerCurrentDelivery: undefined;
  History: undefined;
  HistoryDetails: undefined;
  ProfileScreen: undefined;
};

export type OwnerStackProps<T extends keyof OwnerTabScreens> = CompositeScreenProps<
  NativeStackScreenProps<OwnerTabScreens, T>,
  CompositeScreenProps<StackScreenProps<RootStackScreens>, StackScreenProps<DriverTabScreens>>
>;

const Tab = createBottomTabNavigator<OwnerTabScreens>();

export function OwnerTabNavigator() {
  const currentDelivery = useAppSelector((state) => state.fractions.currentDelivery);

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: globalStyle.NavBarContainer,
        headerShown: false,
      }}
    >
      <Tab.Screen
        component={OwnerFractions}
        name="OwnerFractions"
        options={{
          tabBarTestID: 'Tab_Btn_Fractions',
          tabBarButton: (data) => (currentDelivery ? null : fractionsNavBtn(data)),
        }}
      />

      <Tab.Screen
        component={OwnerFractionDetails}
        name="OwnerFractionDetails"
        options={{ tabBarButton: () => null }}
      />

      <Tab.Screen
        component={OwnerCurrentDelivery}
        name="OwnerCurrentDelivery"
        options={{
          tabBarTestID: 'Tab_Btn_Fractions',
          tabBarButton: (data) => (!currentDelivery ? null : fractionsNavBtn(data)),
        }}
      />

      <Tab.Screen
        component={History}
        name="History"
        options={{
          tabBarTestID: 'Tab_Btn_History',
          tabBarButton: historyNavBtn,
          tabBarHideOnKeyboard: false,
        }}
      />
      <Tab.Screen
        component={HistoryDetails}
        name="HistoryDetails"
        options={{ tabBarButton: () => null }}
      />

      <Tab.Screen
        component={ProfileScreen}
        name="ProfileScreen"
        options={{
          tabBarTestID: 'Tab_Btn_Profile',
          tabBarButton: profileNavBtn,
        }}
      />
    </Tab.Navigator>
  );
}
