import * as React from 'react';
import { storiesOf } from '@storybook/react-native';
import { object } from '@storybook/addon-knobs';
import { Timestamp } from './Timestamp';
import { View } from 'react-native';
import { globalStyle } from '../../../common/styles/global.style';

const props = {
  description: 'Contacting a Tansporter to collect your Fraktion',
  completed: true,
  date: '15.05.2022',
  time: '12:20',
  initiated: true,
};

storiesOf('components/Timestamp', module)
  .addDecorator((story) => (
    <View style={globalStyle.StoryBookAlign}>
      <View style={globalStyle.StorybookDecorator}>{story()}</View>
    </View>
  ))
  .add('completed', () => <Timestamp {...object('props', props)} />)
  .add('uncompleted', () => (
    <Timestamp
      {...object('props', {
        description:
          'Contacting a Tansporter to collect your Fraktion asdknfl ansd lkasmdl malsdm flasdmk lfasdf ',
        completed: false,
        dotted: true,
        date: '15.05.2022',
        time: '12:20',
        initiated: true,
      })}
    />
  ))
  .add('last', () => (
    <Timestamp
      {...object('props', {
        description: 'Contacting a Tansporter to collect your Fraktion',
        completed: false,
        dotted: true,
        isLast: true,
        date: '15.05.2022',
        time: '12:20',
        initiated: true,
      })}
    />
  ));
