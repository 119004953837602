import React, { MutableRefObject } from 'react';
import { View, Text, Image, SafeAreaView, FlatList, RefreshControl } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ownerFractionsScreenStyle } from './OwnerFractions.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, TextButton } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { getAddressString, getTimeSpan } from '../../../../common/utils/utils';

type Props = {
  fractions: FractionWithDelivery[];
  flatListRef?: MutableRefObject<FlatList<FractionWithDelivery>>;
  refreshing?: boolean;
  onSelect?: (fraction: FractionWithDelivery) => void;
  onEndReached?: () => void;
  onRefresh?: () => void;
  onAddFraction?: () => void;
};
export function OwnerFractionsScreen({
  fractions,
  flatListRef,
  refreshing,
  onSelect,
  onEndReached,
  onRefresh,
  onAddFraction,
}: Props) {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" size="LARGE" type="FRACTION" />

        <Text style={globalStyle.TopBarFont}>{t('ownerFractionsScreen.fractions')}</Text>
      </View>

      <FlatList
        ListEmptyComponent={
          <View style={globalStyle.MainContainer}>
            <View style={ownerFractionsScreenStyle.PlaceholderContainer}>
              <Text style={ownerFractionsScreenStyle.PlaceholderFont}>
                {t('ownerFractionsScreen.placeholder')}
              </Text>
            </View>

            <Image
              source={require('../../../../assets/background/AddFractionBg.png')}
              style={ownerFractionsScreenStyle.Background}
            />
          </View>
        }
        ListFooterComponent={<HorizontalSpacer space={80} />}
        data={fractions}
        keyExtractor={(item) => item.id.toString()}
        onEndReached={onEndReached}
        ref={flatListRef}
        refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={refreshing} />}
        renderItem={({ item }: { item: FractionWithDelivery }) => {
          return (
            <View key={`Fraction_${item.id}`} testID={`Fraction_${item.id}`}>
              <FractionCard
                background
                currentPhase={
                  item.ownerPhase.phases.find((p) => p.id == item.ownerPhase.currentPhaseId)?.order
                }
                description={`${item.quantity} ${
                  item.quantity == 1 ? t('common.fractionUnit') : t('common.fractionUnits')
                }, ${item.dimensions.width}cm x ${item.dimensions.length}cm x ${
                  item.dimensions.height
                }cm`}
                dispoId={item.isInterZeroDelivery ? item.interZeroId.toString() : null}
                fractionId={item.uuid.substring(0, 5)}
                historyHeader={`${t('ownerFractionsScreen.publishedOn').replace(
                  '{{time}}',
                  getTimeSpan(item.dateCreated),
                )}`}
                onClick={() => onSelect(item)}
                pickupAddress={getAddressString(item.pickupAddress)}
                reward={{
                  amount: `${(item.isSelfDelivery
                    ? parseFloat(item.deliveryReward.amount) +
                      parseFloat(item.materialReward.amount)
                    : parseFloat(item.materialReward.amount)
                  ).toFixed(0)}`,
                  currency: item.materialReward.currency,
                }}
                title={t(`fractionTypes.${item.type.uuid}`)}
                view={item.isSelfDelivery ? 'OWNER_AS_DRIVER' : 'OWNER'}
              />

              <HorizontalSpacer space={10} />
            </View>
          );
        }}
      />

      <View style={ownerFractionsScreenStyle.AddFractionButton}>
        <TextButton
          bgColor="primaryNavy"
          icon="PLUS"
          onClick={onAddFraction}
          textColor="white"
          title={t('ownerFractionsScreen.addAFraction.title')}
          type="ROUND"
        />
      </View>
    </SafeAreaView>
  );
}
