import { StyleSheet } from 'react-native';
import { Fonts } from '../../../../common/styles/fonts';
import { palette } from '../../../../common/styles/global.style';

export const introScreenStyle = StyleSheet.create({
  Background: {
    height: '55%',
    overflow: 'visible',
    position: 'absolute',
    top: '47%',
    width: '100%',
    zIndex: -1,
  },
  GetStartedButton: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 96,
  },
  IntroTextFont: {
    color: palette.primaryNavy2,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
    marginTop: 37,
    paddingHorizontal: 40,
    textAlign: 'left',
  },
  MainContainer: {
    alignItems: 'center',
    backgroundColor: palette.white,
    flex: 1,
    height: '100%',
    width: '100%',
  },
});
