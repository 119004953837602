import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const loginScreenStyle = StyleSheet.create({
  AlignContainer: {
    alignItems: 'center',
    width: '100%',
  },
  BlueCubeBg: {
    bottom: 0,
    height: 116,
    left: 0,
    opacity: 0.6,
    position: 'absolute',
    width: 90,
    zIndex: -1,
  },
  ForgotPWFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 16,
    paddingLeft: 8,
  },
  HorizontalSpacer: {
    height: 16,
  },
  InputBox: {
    width: '100%',
  },
  InputContainer: {
    alignItems: 'center',
    marginBottom: 32,
    marginTop: 75,
    maxWidth: 390,
    paddingHorizontal: 40,
    width: '100%',
  },
  LinksContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 72,
    marginTop: 48,
    width: '100%',
  },
  MainContainer: {
    backgroundColor: palette.white,
    height: '100%',
    width: '100%',
  },
  SubtitleFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
  },
  TitleFont: {
    color: palette.primaryNavy2,
    fontFamily: Fonts.FranklinGothicHeavy,
    fontSize: 48,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 80,
    marginTop: '25%',
    textTransform: 'uppercase',
  },
  WhiteCubeBg: {
    height: 147,
    opacity: 0.5,
    position: 'absolute',
    right: 0,
    top: 74,
    width: 114,
    zIndex: -1,
  },
});
