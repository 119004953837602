import * as React from 'react';
import { View, Text } from 'react-native';
import { historyDeliveryCardStyle } from './HistoryDeliveryCard.style';
import { useTranslation } from 'react-i18next';
import { FractionWithDelivery } from 'redux/models/fraction.model';
import { useAppSelector } from 'redux/hooks';
import { FractionCard } from '../FractionCard/FractionCard';

type Props = {
  background?: boolean;
  fraction?: FractionWithDelivery;
};

export function HistoryDeliveryCard(props: Props) {
  const { t } = useTranslation();
  const authUser = useAppSelector((state) => state.auth);

  return (
    <View>
      {authUser.role === 'DRIVER' || props.fraction.isSelfDelivery ? (
        <FractionCard
          disposalPoints={[props.fraction.disposalPoint]}
          titleHeader={t('common.disposalSite')}
        />
      ) : (
        <View style={historyDeliveryCardStyle.MainContainer}>
          <Text style={historyDeliveryCardStyle.TransporterTitleFont}>
            {t('fractionDetailsScreen.transporter')}
          </Text>

          <View style={historyDeliveryCardStyle.TransporterContainer2}>
            {/* <ProfilePicture imageUri='https://play-lh.googleusercontent.com/MmLHNN4_lwIN7kMG7XWnOxSYbEju-FBMEn8oDj4Xt8t9EnnH6S6GQeMHJDWpGfeDOSpM' /> */}

            <Text style={historyDeliveryCardStyle.TransporterFont}>
              {`${props.fraction.transporter.firstName} ${props.fraction.transporter.lastName}`}
            </Text>
          </View>

          <Text style={historyDeliveryCardStyle.TransporterFont}>
            {props.fraction.transporter.mobile}
          </Text>
        </View>
      )}
    </View>
  );
}
