import * as React from 'react';
import { Text, View, Modal } from 'react-native';
import { TextButton } from '../TextButton/TextButton';
import { modalStyles } from './Modal.style';
import { useTranslation } from 'react-i18next';

type Props = {
  prop?: number;
  text: string;
  setModal?: (val: boolean) => void;
  action?: () => void;
  open: boolean;
};

export function ConfirmModal(props: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      animationType="none"
      onRequestClose={() => props.setModal(props.open)}
      transparent
      visible={props.open}
    >
      <View style={modalStyles.Main}>
        <View style={modalStyles.Modal}>
          <View style={modalStyles.messageContainer}>
            <Text style={modalStyles.Message}>{props.text}</Text>
          </View>
          <View style={modalStyles.buttonsContainer}>
            <TextButton
              bgColor="black"
              onClick={() => props.setModal(props.open)}
              style={modalStyles.Button}
              textColor="white"
              title={t('common.no')}
              type="SQUARE"
            />
            <TextButton
              bgColor="red2"
              onClick={() => {
                props.setModal(props.open);
                props.action();
              }}
              style={modalStyles.Button}
              textColor="white"
              title={t('common.yes')}
              type="SQUARE"
            />
          </View>
        </View>
      </View>
    </Modal>
  );
}
