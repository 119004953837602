import React, { useState } from 'react';
import { View, Text, ScrollView, SafeAreaView, RefreshControl } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ownerFractionDetailsStyle } from './OwnerFractionDetails.style';
import { globalStyle } from '../../../../common/styles/global.style';
import { IconButton, TextButton, Timestamp } from '../../../components';
import { FractionCard } from '../../../groups/FractionCard/FractionCard';
import { HorizontalSpacer } from '../../../components/HorizontalSpacer/HorizontalSpacer';
import {
  OWNER_AS_DRIVER_PHASES_COMPLETE,
  OWNER_PHASES_COMPLETE,
} from '../../../../common/utils/constants';
import { getPhases, getTimeSpan } from '../../../../common/utils/utils';
import moment from 'moment';
import { PhasesEnum } from '../../../../redux/models/phase.model';
import { ConfirmModal } from 'ui/components/Modal/Modal';
import { FractionWithDelivery } from '../../../../redux/models/fraction.model';
import { PickUpDateCard } from '../../../groups';

type Props = {
  fraction: FractionWithDelivery;
  refreshing?: boolean;
  onRefresh?: () => void;
  onDeleteFraction?: () => void;
  onArchiveFraction?: () => void;
  onIconClick?: () => void;
};

export function OwnerFractionDetailsScreen({
  fraction,
  refreshing,
  onRefresh,
  onDeleteFraction,
  onArchiveFraction,
  onIconClick,
}: Props) {
  const { t } = useTranslation();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  return (
    <SafeAreaView style={globalStyle.MainContainer}>
      {openConfirmModal ? (
        <ConfirmModal
          action={onDeleteFraction}
          open={openConfirmModal}
          setModal={(val) => setOpenConfirmModal(!val)}
          text={modalMessage}
        />
      ) : null}
      <View style={globalStyle.HeaderContainer}>
        <IconButton color="primaryNavy" onClick={onIconClick} size="LARGE" type="PREVIOUS" />

        <Text style={globalStyle.TopBarFont}>{t('fractionDetailsScreen.details')}</Text>
      </View>

      <ScrollView
        refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={refreshing} />}
        style={globalStyle.MainContainer}
      >
        <FractionCard
          background
          currentPhase={
            fraction.ownerPhase.phases.find((p) => p.id == fraction.ownerPhase.currentPhaseId)
              ?.order
          }
          description={`${fraction.quantity} ${
            fraction.quantity == 1 ? t('common.fractionUnit') : t('common.fractionUnits')
          }, ${fraction.dimensions.width}cm x ${fraction.dimensions.length}cm x ${
            fraction.dimensions.height
          }cm`}
          dispoId={fraction.isInterZeroDelivery ? fraction.interZeroId.toString() : null}
          fractionId={fraction.uuid.substring(0, 5)}
          historyHeader={`${t('ownerFractionsScreen.publishedOn').replace(
            '{{time}}',
            getTimeSpan(fraction.dateCreated),
          )}`}
          pickupAddress={`${fraction.pickupAddress.streetName}, ${fraction.pickupAddress.postCode} ${fraction.pickupAddress.city}`}
          reward={{
            amount: `${(fraction.isSelfDelivery
              ? parseFloat(fraction.deliveryReward.amount) +
                parseFloat(fraction.materialReward.amount)
              : parseFloat(fraction.materialReward.amount)
            ).toFixed(0)}`,
            currency: fraction.materialReward.currency,
          }}
          title={t(`fractionTypes.${fraction.type.uuid}`)}
          view={fraction.isSelfDelivery ? 'OWNER_AS_DRIVER' : 'OWNER'}
        />

        <HorizontalSpacer space={8} />

        {(() => {
          const pickupDate = fraction.transporterPhase?.phases?.find(
            (x) => x.name == PhasesEnum.T_PICKUP,
          ).dateStarted;
          return !fraction.isSelfDelivery && pickupDate ? (
            <>
              <PickUpDateCard dateTime={pickupDate} />
              <HorizontalSpacer space={8} />
            </>
          ) : null;
        })()}

        {!fraction.isSelfDelivery && fraction.transporter && !fraction.isInterZeroDelivery ? (
          <>
            <View style={ownerFractionDetailsStyle.TransporterContainer}>
              <Text style={ownerFractionDetailsStyle.TransporterTitleFont}>
                {t('fractionDetailsScreen.transporter')}
              </Text>

              <View style={ownerFractionDetailsStyle.TransporterContainer2}>
                {/* <ProfilePicture imageUri='https://play-lh.googleusercontent.com/MmLHNN4_lwIN7kMG7XWnOxSYbEju-FBMEn8oDj4Xt8t9EnnH6S6GQeMHJDWpGfeDOSpM' /> */}

                <Text style={ownerFractionDetailsStyle.TransporterFont}>
                  {`${fraction.transporter.firstName} ${fraction.transporter.lastName}`}
                </Text>
              </View>

              <Text style={ownerFractionDetailsStyle.TransporterFont}>
                {fraction.transporter.mobile}
              </Text>
            </View>

            <HorizontalSpacer space={8} />
          </>
        ) : null}

        <View style={ownerFractionDetailsStyle.TimestampsContainer}>
          {getPhases(fraction.ownerPhase.phases, fraction.datePaymentReceived).map((phase) => (
            <Timestamp
              date={moment(phase.dateStarted).format(t('time.dateFormat'))}
              description={
                fraction.isSelfDelivery
                  ? OWNER_AS_DRIVER_PHASES_COMPLETE[phase.name]
                  : OWNER_PHASES_COMPLETE[phase.name]
              }
              dotted={phase.dateFinished ? false : true}
              initiated={phase.dateStarted ? true : false}
              isLast={phase.name == PhasesEnum.COMPLETE}
              key={`${phase.name}`}
              name={`${phase.name}`}
              time={moment(phase.dateStarted).format(t('time.timeFormat'))}
            />
          ))}
        </View>

        <HorizontalSpacer space={200} />
      </ScrollView>

      <View style={ownerFractionDetailsStyle.FinishButtonContainer}>
        {!fraction.transporter ? (
          <TextButton
            bgColor="red2"
            icon="DELETE"
            onClick={() => {
              setModalMessage(t('fractionDetailsScreen.confirmDelete'));
              setOpenConfirmModal(!openConfirmModal);
            }}
            style={ownerFractionDetailsStyle.FinishButton}
            textColor="white"
            title={t('fractionDetailsScreen.delete')}
            type="SQUARE"
          />
        ) : (
          <TextButton
            bgColor="black"
            icon="TICK"
            onClick={onArchiveFraction}
            style={ownerFractionDetailsStyle.FinishButton}
            textColor="white"
            title={t('fractionDetailsScreen.finish')}
            type="SQUARE"
          />
        )}
      </View>
    </SafeAreaView>
  );
}
