import { StyleSheet } from 'react-native';
import { Fonts } from '../../../../common/styles/fonts';
import { palette } from '../../../../common/styles/global.style';

export const driverFractionsScreenStyle = StyleSheet.create({
  Background: {
    alignSelf: 'center',
    aspectRatio: 1,
    height: '50%',
    maxWidth: 400,
    overflow: 'visible',
    resizeMode: 'contain',
    width: '80%',
  },
  NotificationButton: {
    alignItems: 'flex-end',
    flex: 1,
  },
  PlaceholderContainer: {
    flex: 1,
    marginTop: '45%',
    paddingHorizontal: 24,
    width: '100%',
    zIndex: 1,
  },
  PlaceholderFont: {
    color: palette.grey7,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
    marginLeft: 16,
    marginTop: 2,
    textAlign: 'center',
  },
});
