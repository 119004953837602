import React, { useEffect, useState } from 'react';
import { flatFractionsWithDelivery } from '../../../common/utils/utils';
import { DriverStackProps } from '../../../navigation/DriverTabNavigator';
import { OwnerStackProps } from '../../../navigation/OwnerTabNavigator';
import { useGetAllArchivedDeliveriesQuery } from '../../../redux/apis/fraction.api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { FractionWithDelivery } from '../../../redux/models/fraction.model';
import {
  addHistoriesToPagedList,
  clearHistoryList,
  getHistoryList,
  nextHistoryPage,
  selectHistory,
} from '../../../redux/slices/history.slice';
import { HistoryScreen } from './HistoryScreen';

const pageSize = 10;

export function History({
  navigation,
}: OwnerStackProps<'OwnerFractions'> & DriverStackProps<'DriverFractions'>) {
  const dispatch = useAppDispatch();

  const [refreshing, setRefreshing] = useState(false);
  const fractions = useAppSelector(getHistoryList);
  const page = useAppSelector((state) => state.history.page);

  const archivedDeliveriesQuery = useGetAllArchivedDeliveriesQuery({
    pageNumber: page,
    pageSize,
    sortDir: 'DESC',
  });

  useEffect(() => {
    if (archivedDeliveriesQuery.isSuccess) {
      setRefreshing(false);
      const fractionsWithDelivery = flatFractionsWithDelivery(archivedDeliveriesQuery.data.data);
      dispatch(addHistoriesToPagedList({ page, fractions: fractionsWithDelivery }));
    }
  }, [archivedDeliveriesQuery.data, archivedDeliveriesQuery.isFetching]);

  const user = useAppSelector((state) => state.auth);

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(clearHistoryList());
    archivedDeliveriesQuery.refetch();
  };

  const handleSelect = (fraction: FractionWithDelivery) => {
    dispatch(selectHistory(fraction));
    navigation.navigate('HistoryDetails');
  };

  return (
    <HistoryScreen
      fractions={fractions}
      onEndReached={() =>
        page < archivedDeliveriesQuery.data.totalPages && dispatch(nextHistoryPage())
      }
      onRefresh={handleRefresh}
      onSelect={handleSelect}
      refreshing={refreshing}
      role={user.role}
    />
  );
}
