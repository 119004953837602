import { StyleSheet } from 'react-native';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const timestampStyle = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 70,
    paddingLeft: 56,
    paddingRight: 16,
    paddingVertical: 8,
    position: 'relative',
  },
  DateFont: {
    color: palette.grey6,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: 24,
    marginTop: 4,
    textAlign: 'right',
  },
  DescriptioFont: {
    color: palette.grey7,
    flex: 1,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
  },
  Hider: {
    backgroundColor: palette.grey1,
    height: 60,
    left: 25,
    position: 'absolute',
    top: 10,
    width: 6,
    zindex: 1,
  },
  TickIconContainer: {
    backgroundColor: palette.accentNavy3,
    borderColor: palette.accentNavy3,
    borderRadius: 8,
    borderWidth: 1,
    height: 16,
    left: 20,
    paddingLeft: 2,
    paddingTop: 2,
    position: 'absolute',
    top: 8,
    width: 16,
    zIndex: 2,
  },
  TimeContainer: {
    alignSelf: 'flex-end',
    flexDirection: 'column',
    marginLeft: 'auto',
    width: 95,
  },
  TimeFont: {
    color: palette.grey8,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: 32,
    textAlign: 'right',
  },
  VerticalLine: {
    left: 25,
    position: 'absolute',
    top: 10,
    zindex: 1,
  },
});
